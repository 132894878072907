<template>
    <div>
        <Language></Language>

        <AppMenu1 v-if="app.theme === 1"></AppMenu1>
    </div>
</template>

<script>
  import AppMenu1 from "./AppMenu/AppMenu-1"
  import Language from "./AppMenu/LanguageSwitcher"
  import { mapStores } from 'pinia'
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    components: {
      AppMenu1,
      Language
    },
    data () {
      return {}
    },
    mounted () {
      if (this.app.active === false) {
        this.$router.push('/')
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      app() {
        return this.appTemplateStore.get('app')
      }
    }
  }
</script>
