<template>
  <div v-bind:class="['voting-container-'+device]">
    <transition name="slide-fade">
      <div v-if="isQuestionActive && showQuestion">
        <div class="voting-room-header">
          <h3>{{ active_question.question }}</h3>
        </div>
        <div class="voting-content">
          <form class="voting-form" @submit.prevent ref="form">
            <template v-if="active_question.type == 'single-choice'">
              <div
                class="voting-choice btn-1"
                v-for="(option) in active_question.options"
                :id="'question-' + option.option_id"
                v-bind:class="[
                  answer_option == option.option_id ? 'voting-choice-selected' : '',
                ]"
                :key="option.option_id"
              >
                <input
                  type="radio"
                  v-model="answer_option"
                  v-on:change="sendAnswer"
                  :id="'opt' + option.option_id"
                  :value="option.option_id"
                />
                <label :for="'opt' + option.option_id" class="form-check-label voting-option-label">
                  {{ option.option }}
                </label>
              </div>
            </template>
            <div v-if="active_question.type == 'open'">
              <input
                type="text"
                name="voting-textarea"
                class="voting-textarea"
                v-model="answer_open"
                :placeholder="translate('voting_type_here')"
              />
              <button class="btn btn-1" v-on:click="sendAnswer()">
                {{ translate("voting_send") }}
              </button>
            </div>
          </form>
        </div>
        <div class="voting-result-sending" v-if="answer_sent">
          <div class="voting-result-message btn-1">
            <span
              v-if="success_voting"
              v-html="translate('voting_thank_you')"
              class="voting-message"
            ></span>
            <span
              v-else
              v-html="this.error_message"
              class="voting-message"
            ></span>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="messageNoQuestion" class="voting-no-active-question">
      <p v-html="this.translate('voting-no-active-question')"></p>
    </div>
  </div>
</template>

<script>
import { env } from "@/env";
import { io } from "socket.io-client"
import { mapStores } from "pinia"
import { useAppVoting } from "@/stores/app-voting"
import { useAppLiveModule } from "@/stores/app-liveModule"

export default {
  name: "voting",
  props: {
    voting: Object,
    device: String
  },
  data() {
    return {
      active_question: null,
      answer_open: "",
      answer_option: "",
      answer_sent: false,
      success_voting: Boolean,
      error_message: "",
      showQuestion: true,
      messageNoQuestion: false,
      socket: io(env.VOTING_SERVER + "/" + this.voting.hash, {
        secureConnection: true,
        reconnection: true,
        forceNew: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        transports: ["websocket","polling"],
        query: {
          token: localStorage.getItem("x-momice-token"),
          eventUrl: window.location.hostname,
          hash: this.voting.hash,
          interactionParticipantHash: this.interactionParticipantHash
        },
      }),
    };
  },
  beforeUnmount() {
    this.socket.close();
  },
  watch: {
    active_question(currVal, prevVal) {
      if (!currVal || currVal.question === null) {
        this.showQuestion = false;
        setTimeout(() => (this.messageNoQuestion = true), 500);
      } else if (currVal != null && prevVal != null && currVal.question_id === prevVal.question_id) {
        this.messageNoQuestion = false;
        this.showQuestion = true;
      } else {
        this.messageNoQuestion = false;
        this.showQuestion = false;
        setTimeout(() => (this.showQuestion = true), 500);
      }
    },
  },
  created() {
    this.socket.on("active-question", (data) => {
      this.active_question = data;
      if(this.active_question == null) {
        this.messageNoQuestion = true;
      }
    });

    this.socket.on("answer-response", (data) => {
      if (data.hash && data.question_id) {
        this.success_voting = true;
        this.answer_open = "";
        this.appVotingStore.setCurrentAnswer({
          type: data.type,
          option_id: data.option_id,
          answer: data.answer,
        })
      } else {
        this.success_voting = false;
        //this.error_message = result.message; error from backend
      }
    });

    this.socket.on("interaction-participant", (data) => {
      this.appLiveModuleStore.setInteractionParticipantHash(data);
    })
  },
  methods: {
    sendAnswer() {
      let hash = this.voting.hash;
      let question_id = this.active_question.question_id;
      let answer = this.answer_open;
      let option_id = this.answer_option;
      let type = this.active_question.type;

      this.answer_sent = true;

      if (answer || option_id) {
        this.socket.emit("answer", {
          hash,
          question_id,
          option_id,
          answer,
          type,
          interactionParticipantHash: this.interactionParticipantHash
        });
      } else {
        this.success_voting = false;
        this.error_message = this.translate("voting_answer_empty");
      }
      setTimeout(() => (this.answer_sent = false), 3000);
    },
  },
  computed: {
    ...mapStores(useAppVoting, useAppLiveModule),
    currentAnswer() {
      return this.appVotingStore.getCurrentAnswer
    },
    isQuestionActive() {
      return this.active_question && this.active_question.question
        ? true
        : false;
    },
    interactionParticipantHash() {
      return this.appLiveModuleStore.interactionParticipantHash ?? null
    }
  },
  components: {},
};
</script>

<style lang="sass" type="text/sass">
.voting-container-mobile, .voting-container-desktop
  height: 100%
  .voting-room-header
    h3
      font-weight: bold
      color: #000000
  .voting-content
    margin-top: 30px
    .voting-form
      width: 100%
      .voting-choice
        width: 100%
        min-height: 40px
        margin-bottom: 15px
        padding: 8px
        position: relative
        opacity: 0.7
        cursor: pointer
        input
          position: absolute
          top: 12px
          left: 10px
          visibility: hidden
        label
          line-height: 23px
          width: 100%
        &-selected
          opacity: 1
      .voting-textarea
        border: none
        height: 40px
        padding: 10px
        width: 100%
        background-color: #f9f9f9
        border: solid 0.5px #e3e3e3
        margin-bottom: 20px
      .voting-send-btn
        text-align: center
        width: 100%
        margin-top: 30px
      .btn
        width: 100%

  .voting-result-sending
    position: fixed
    right: 0
    left: 0
    bottom: 0
    height: 150px
    display: -webkit-box
    background-color: white
    .voting-result-message
      display: flex
      align-items: center
      text-align: center
      width: 100%
      opacity: 0.7
      .voting-message
        width: 100%
        font-size: 120%
        *
          color: inherit
          margin-bottom: 0px
        span
          font-size: 30px
          font-weight: 600

  .voting-no-active-question
    position: absolute

.voting-container-desktop
  padding: 25px
  .voting-result-sending
    position: absolute
    right: 0
    left: 0
    bottom: 50px
    height: 150px
    display: -webkit-box
    background-color: white

.voting-option-label
  cursor: pointer

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active
  transition: all .8s ease

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0
</style>
