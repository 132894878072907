<template>
  <div class="chat-container" :style="`background-color: ${colors(6)};`">
    <div v-if="!currentRoom" class="chat-join-room">
      <div class="chat-room-action">
        <button
          type="button"
          :class="'btn btn-1 chat-room-action-join ' + joinChatDisabled"
          @click="joinRoom(programItem.chat.hash)"
        >{{ translate('join_chat') }}</button>
      </div>
    </div>
    <ChatUserInfo v-else-if="!currentUser" />
    <Room v-else :roomHash="currentRoom" :user="currentUser" :roomName="programItem.chat.name" />
  </div>
</template>

<script>
import Room from "./Room.vue";
import ChatUserInfo from "./ChatUserInfo.vue";
import RoomsList from "./RoomsList.vue";
import { mapStores } from "pinia"
import { useAppChat } from "@/stores/app-chat"


export default {
  name: "chat",
  components: {
    RoomsList,
    ChatUserInfo,
    Room
  },
  props: {
    programItem: Object,
    preview: Boolean
  },
  beforeUnmount() {
    this.appChatStore.setCurrentRoom(null)
  },
  methods: {
    joinRoom(hash) {
      this.appChatStore.joinRoom({ hash }).then(({ accessAllowed }) => {
        if (accessAllowed) {
          this.appChatStore.setCurrentRoom(hash)
        }
      });
    }
  },
  computed: {
    ...mapStores(useAppChat),
    currentUser() {
      return this.appChatStore.getCurrentUser
    },
    currentRoom() {
      return this.appChatStore.getCurrentRoom
    },
    rooms() {
      return this.appChatStore.getRooms
    },
    joinChatDisabled() {
      return this.preview ? 'd-none' : '';
    }
  }
};
</script>

<style lang="sass" type="text/sass" scoped>
.chat-container
  height: 100%
  display: flex
  flex-direction: column
  justify-content: end

.chat-room-action
  height: 53px
  .chat-room-action-join
    height: 100%
    width: 100%
</style>
