<template>
  <div>
    <label id="prevPage" @click="previousPage()" role="link" tabindex="0" @keypress.enter="previousPage()" data-cy="new-go-timeline"><i class="icon-arrow-left align-middle return-link"></i> Timeline</label>
    <div class="justify-content-center">
      <div class="news-wrapper">
        <news-header :data="post" :title="post.title"></news-header>
        <div class="news-card body">
          <div class="video-wrapper video-wrapper-16-9">
              <div ref="youtube-player" id="youtube-player" v-if="post.extra.type === 'youtube'"></div>
              <div ref="vimeo-player" id="vimeo-player" v-if="post.extra.type === 'vimeo'" :data-vimeo-id="post.extra.value"></div>
          </div>
          <div class="news-card title">{{ post.extra.title }}</div>
        </div>
        <news-footer :data="post" :isOverview="false"></news-footer>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
#prevPage
  cursor: pointer

.news-wrapper
  width: 100%
  margin: 20px 0px
  display: flex
  position: relative
  border-radius: 6px
  flex-direction: column
  border: 1px solid rgba(0, 0, 0, 0.12)

.body
  padding: 0px

.title
  padding: 16px

.video-wrapper
  margin-bottom: 0px

.video-wrapper-16-9
  position: relative
  padding-bottom: 56.25% /* 16:9 */
  padding-top: 25px
  height: 0

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

#prevPage
  @media (max-width: 1279px)
    display: none

</style>
<script>
  import Header from "../NewsHeader.vue"
  import Footer from "../NewsFooter.vue"
  import YouTubePlayer from "youtube-player"
  import Vimeo from "@vimeo/player"

  export default {
    props: {
      post: Object
    },
    methods: {
      previousPage () {
        this.$router.go(-1)
      },
      initVideo () {
        this.$nextTick(() => {
          if (this.post.extra) {
            if (this.post.extra.type === 'youtube' && this.$refs['youtube-player']) {
              if (this.ytPlayer) {
                this.ytPlayer.destroy()
              }

              this.ytPlayer = YouTubePlayer('youtube-player', {
                videoId: this.post.extra.value,
                playerVars: {
                  'rel': 0,
                  'disablekb': 1
                }
              })
            }

            if (this.post.extra.type === 'vimeo') {
              let options = {
                id: this.post.extra.value
              }

              /* eslint-disable no-new */
              new Vimeo('vimeo-player', options)
            }
          }
        })
      }
    },
    watch: {
      '$route.path' () {
        this.initVideo()
      }
    },
    mounted () {
      this.initVideo()
    },
    components: {
      'news-header': Header,
      'news-footer': Footer
    }
  }
</script>
