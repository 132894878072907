<template>
  <div>
    <p class="thank-you">{{ translate('rating_thankyou') }}</p>
    <p class="explanation">{{ translate('rating_explanation') }}</p>
    <div id="lm-session-rating-rate">
      <div class="rating-stars" >
        <input type="radio" v-model="rating" id="star1" value="1" />
        <label :class="'fa ' + (parseInt(this.rating) >= 1 ? 'icon-star-colored' : 'icon-star-outlined')" for="star1" title="1 stars"></label>
        <input type="radio" v-model="rating" id="star2" value="2" />
        <label :class="'fa ' + (parseInt(this.rating) >= 2 ? 'icon-star-colored' : 'icon-star-outlined')" for="star2" title="2 stars"></label>
        <input type="radio" v-model="rating" id="star3" value="3" />
        <label :class="'fa ' + (parseInt(this.rating) >= 3 ? 'icon-star-colored' : 'icon-star-outlined')" for="star3" title="3 stars"></label>
        <input type="radio" v-model="rating" id="star4" value="4" />
        <label :class="'fa ' + (parseInt(this.rating) >= 4 ? 'icon-star-colored' : 'icon-star-outlined')" for="star4" title="4 stars"></label>
        <input type="radio" v-model="rating" id="star5" value="5" />
        <label :class="'fa ' + (parseInt(this.rating) == 5 ? 'icon-star-colored' : 'icon-star-outlined')" for="star5" title="5 stars"></label>
      </div>
    </div>
    <div id="lm-session-rating-buttons">
      <button class="btn btn-2" v-on:click="skipRating()">{{ translate('rating_skip') }}</button>&nbsp;
      <button :class="sendRatingActive ? 'btn btn-1' : 'btn btn-1 disabled'" v-on:click="sendRating()">{{ translate('rating_send') }}</button>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>

p.thank-you
  margin-bottom: 0
  margin-top: 35px
  font-size: 140%
p.explanation
  margin-bottom: 0
  margin-top: 35px
  font-size: 110%
  padding-bottom: 20px

#lm-session-rating-rate
  display: flex
  justify-content: center
  .rating-stars
    float: left
    height: 46px
    padding: 0 10px

    label
      font-size: 40px
      cursor: pointer

  .rating-stars:not(:checked) > input
    position: absolute
    top: -9999px

#lm-session-rating-buttons
  padding: 25px

</style>
<script>
  import { mapStores } from 'pinia'
  import { useAppLiveModule } from '@/stores/app-liveModule'

  export default {
    props: {
      nextAction: Function,
      streamHash: String
    },
    data () {
      return {
        rating: ''
      }
    },
    computed: {
      ...mapStores(useAppLiveModule),
      sendRatingActive() {
        if(this.rating > 0) {
          return true;
        }
        return false;
      },
      currentRating() {
        return this.appLiveModuleStore.getCurrentRating
      }
    },
    methods: {
      sendRating() {
        let hash = this.streamHash;
        let rating = this.rating;
        let dataRating = this.currentRating;

        dataRating.push({hash,rating});

        if(rating > 0) {
          this.appLiveModuleStore.sendRating({hash, rating}).then((result) => {
            if(result.rated) {
              this.appLiveModuleStore.setCurrentRating(dataRating)
              this.nextAction();
            }
          })
        }
      },
      skipRating() {
        let hash = this.streamHash;
        let rating = "skipped";
        let dataRating = this.currentRating;

        dataRating.push({hash,rating});

        this.appLiveModuleStore.setCurrentRating(dataRating)
        this.nextAction();
      }
    }
  }
</script>
