<!-- 
    ME-929 
    Page temporary blank to avoid "self check-in" from the visitors.
    The component can be removed in the future
-->
<template>
    <div></div>
</template>

<style type="text/scss" lang="scss" scoped>
</style>

<script>
    import http from './../http'

    export default {
      data () {
        return {
          title: '',
          content: null,
          summary: {}
        }
      }
    }
</script>