<template>
  <div>
    <div
      class="buttons"
      :class="{ rtl: menuPosition === 'left' }"
    >
      <router-link
        v-if="can_register && menuActive"
        v-slot="{ navigate }"
        to="/register"
        custom
      >
        <button
          role="link"
          class="btn btn-3 d-none d-lg-inline"
          tabindex="0"
          @click="navigate"
          @keypress.enter="navigate"
        >
          {{ translate('register_call_to_action') }}
        </button>
      </router-link>
      <button
        v-if="menuActive"
        role="link"
        tabindex="0"
        class="btn btn-1 d-none d-lg-inline" 
        @keypress.enter="openMenu(false)" 
        @click="openMenu(false)"
      >
        Menu
      </button>
    </div>

    <!-- ROUTE SPECIFIC MENU (/menu) -->
    <div
      v-if="menuActive"
      :aria-label="translate('open_menu')"
      role="navigation"
      tabindex="0"
    >
      <i
        v-if="isAppMenuActive"
        key="route_menu"
        class="menu icon-menu align-middle hamburger burger1"
        aria-hidden="true"
        tabindex="0"
        @click="openMenu(true)"
        @keypress.enter="openMenu(true)"
      />
      <!-- NORMAL MENU -->
      <i
        v-else
        key="normal_menu"
        class="menu icon-menu align-middle hamburger burger2"
        aria-hidden="true"
        tabindex="0"
        @click="openMenu(false)"
        @keypress="openMenu(false)"
      />
    </div>

    <div
      v-if="isMenuOpen"
      id="overlay"
      @click="closeMenu"
    />
    <div
      v-if="isMenuOpen"
      id="menu-1"
      ref="menuOne"
      :class="{ open: isMenuOpen }"
      aria-label="Main menu"
      :aria-expanded="isMenuOpen"
    >
      <div
        id="close"
        :aria-label="translate('close_menu')"
        class="d-flex align-items-center"
      >
        <i
          class="icon-false-close"
          aria-hidden="true"
          @click="closeMenu"
          @keypress.enter="closeMenu"
        />
      </div>
      <ul>
        <li v-if="showHomepage">
          <i
            class="icon-home"
            aria-hidden="true"
          />
          <router-link
            :class="{ active: $route.name === 'home' }"
            :aria-current="ariaCurrentHomePage($route.name)"
            :to="{ name: 'home' }"
          >
            <span>Home</span>
          </router-link>
        </li>
        <li v-if="showLounge">
          <i
            class="icon-home"
            aria-hidden="true"
          />
          <router-link
            :class="{ active: $route.name === 'lounge'}"
            :aria-current="ariaCurrentLounge($route.name)"
            :to="{ name: 'lounge'}"
          >
            <span>Home</span>
          </router-link>
        </li>
        <template v-for="(p, index) in pages">
          <li
            v-if="p.type == 'external'"
            :key="index"
            @keydown.tab="handleTab(index)"
          >
            <i
              :class="p.icon"
              aria-hidden="true"
            />
            <a
              :href="JSON.parse(p.extra).link"
              target="_blank"
            >
              <span>{{ p.title }}</span>
            </a>
          </li>
          <li
            v-else
            :key="p.page_id"
            @keydown.tab="handleTab(index)"
          >
            <i
              :class="p.icon"
              aria-hidden="true"
            />
            <router-link
              :class="{ active: p.page_id === parseInt($route.params.pageId) }"
              :aria-current="ariaCurrentPage(p.page_id,$route.params.pageId)"
              :to="{ name: 'page', params: { pageId: p.page_id } }"
            >
              <span>{{ p.title }}</span>
            </router-link>
          </li>
        </template>
      </ul>
      <LanguageSwitcher />
    </div>
  </div>
</template>

<script>
import moment from "moment"
import LanguageSwitcher from "./Menu1/Language"
import ariaMixin from "../../mixins/ariaMixin"
import { mapStores } from "pinia"
import { useAppLanguages } from "@/stores/app-languages"
import { useAppMenu } from "@/stores/app-menu"
import { useAppUi } from "@/stores/app-ui"
import { useAppLounge } from "@/stores/app-lounge"
import { useAppTemplate } from "@/stores/app-template"
import { useVisitor } from "@/stores/visitor"
import { useAppPages } from "@/stores/app-pages"
import { useApp } from "@/stores/app"

export default {
  components: {
    LanguageSwitcher
  },
  mixins: [
    ariaMixin
  ],
  props: {
    menuPosition: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {};
  },computed: {
    ...mapStores(useAppLanguages, useAppMenu, useAppUi, useAppLounge, useAppTemplate, useVisitor, useAppPages, useApp),
    breakpointMenuActive() {
      return this.breakpoint.size <= 2;
    },
    breakpoint() {
      return this.appUiStore.breakingPoint
    },
    showLounge() {
      return this.appLoungeStore.active
    },
    showHomepage() {
      let { homepage: showHomepage = true } = this.template;
      return showHomepage;
    },
    template() {
      return this.appTemplateStore.template
    },
    can_register() {
      if (!this.event.registration) {
        return false; // registration is turned off
      }

      let lid = this.visitorStore.data.lid
      let page = this.event.pages.find(
        p => p.type === "register" && p.language_id === lid
      );

      if (!page || page.active !== "yes") {
        return false; // page does not exist or page is not active
      }

      if (this.event.registration_close_date === null) {
        return true; // close date is not configured
      }

      let closedate = moment(this.event.registration_close_date);
      return moment() < closedate; // close date has passed
    },
    isMenuOpen() {
      return this.appMenuStore.open
    },
    pages() {
      return this.appPagesStore.menu
    },
    event() {
      return this.appStore.event
    },
    isAppMenuActive() {
      return this.appTemplateStore.get("app.active")
    },
    languages() {
      return this.appLanguagesStore.all
    },
    menuActive () {
      return this.pages.length > 1 || this.showHomepage || this.showLounge || this.languages.length > 1
    }
  },
  mounted() {
    window.addEventListener('keyup', this.closeMenuKeyEscape);
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.closeMenuKeyEscape);
  },
  methods: {
    closeMenu() {
      this.appMenuStore.setOpen(false)
    },
    openMenu(app = false) {
      if (app && this.isAppMenuActive && this.breakpointMenuActive) {
        // switch to app menu if available
        this.$router.push("/menu");
      } else {
        this.appMenuStore.setOpen(true)
      }
    },
    closeMenuKeyEscape(event) {
      if (event.key === 'Escape'){
        this.closeMenu()
      }
    },
    handleTab(index) {
      if (index === this.pages.length - 1) {
        this.closeMenu()
      }
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.hamburger
  display: inline-block !important

.icon-false-close:focus-visible,
.hamburger:focus-visible
  outline: 2px dotted !important
  outline-offset: 3px

@include media-breakpoint-up(lg)
  .burger1
    display: none !important

@include media-breakpoint-up(lg)
  .burger2
    display: none !important

.buttons
  button:nth-child(n+2)
    margin-left: 7px

.buttons.rtl
  button:nth-child(n+2)
    margin-right: 7px

.rtl
  direction: rtl

  *
    direction: ltr


i.menu
  cursor: pointer

i.icon-menu
  font-size: 24px

#overlay
  position: fixed
  top: 0
  left: 0
  z-index: 8799
  width: 100%
  height: 100%

#menu-1
  position: fixed
  width: auto
  max-width: 296px
  min-width: 200px
  padding: 0 50px
  padding-top: $topbar-height-large
  padding-bottom: 50px
  height: 100%
  overflow-y: auto
  top: 0
  z-index: 8800

  ul
    list-style-type: none
    font-size: 1.0rem
    margin: 0
    padding: 0
    font-weight: 300
    width: 100%

    li
      font-size: 1rem
      display: flex
      align-items: flex-start
      line-height: 26px
      padding-bottom: 25px

      i
        font-size: 1.78rem
        display: inline-block
        font-style: normal
        align-self: flex-start
        margin-right: 15px

      span
        display: inline-block
        align-self: center

    li:hover, a.active
      cursor: pointer

      span
        text-decoration: underline

  #close
    position: absolute
    z-index: 8801
    top: 0
    left: 0
    padding: 0 50px
    width: 100%
    height: $topbar-height-large
    cursor: pointer

    i
      display: inline
      color: inherit
      font-size: 32px

@include media-breakpoint-down(lg)
  #menu-1
    padding-top: $topbar-height-small

    #close
      height: $topbar-height-small
</style>
