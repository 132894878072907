<template>
  <div class="page-link-expired mm-page page-flex">
    <div class="container inner">
      <div class="row content mm-html" v-html="textLinkExpired">
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useVisitor } from '@/stores/visitor'

export default {
  computed: {
    ...mapStores(useVisitor),
    textLinkExpired() {
      if (this.translationExists('text_link_expired') && !this.isTestMode) {
        return this.translate('text_link_expired')
      }

      return this.translationExists('text_link_expired_test_email') && this.isTestMode ? this.translate('text_link_expired_test_email') : ''
    },
    isTestMode() {
      return this.visitorStore.testMode
    },
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
</style>
