<template>
  <div class="line" :class="hideForClasses" :style="lineStyle"></div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    props: {
      height: {
        type: Number,
        default: 1,
        required: false
      },
      color: {
        type: String,
        default: '#C2C2C2',
        required: false
      },
      width: {
        type: Number,
        default: 100,
        required: false
      },
      margin: {
        type: Number,
        default: 30,
        required: false
      },
      hideFor: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      hideForClasses () {
        let valid = 'xs,sm,md,lg,xl'.split(',')
        return this.hideFor.map(size => {
          if (~valid.indexOf(size)) {
            return `d-${size}-none`
          }
        }).join(' ').trim()
      },
      lineStyle () {
        const colors = this.appTemplateStore.get('colors')
        return {
          height: `${this.height}px`,
          width: `${this.width}%`,
          backgroundColor: colors['5'],
          margin: `${this.margin}px 0px`
        }
      }
    }
  }
</script>