<template>
  <div
    v-if="(showScrollToTop && scrolled) || forceShowScrollToTop"
    id="scrollToTopButton"
    @click="scrollToTop(500)"
  >
    <i
      class="icon-arrow-up"
      :aria-label="translate('scroll_to_top')"
      tabindex="0"
    />
  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppUi } from '@/stores/app-ui'

  export default {
    data () {
      return {
        scrolled: false
      }
    },
    computed: {
      ...mapStores(useAppUi),
      showScrollToTop () {
        return this.appUiStore.scrollToTop
      },
      forceShowScrollToTop () {
        return this.appUiStore.forceShowScrollToTop
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll () {
        this.scrolled = window.scrollY > 200
      },
      scrollToTop () {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div#scrollToTopButton
  position: fixed
  display: table-row
  z-index: 7000
  bottom: 50px
  right: 50px
  width: 45px
  height: 45px
  border-radius: 45px
  text-align: center
  cursor: pointer
  box-shadow: 0px 2px 10px rgba(0,0,0,0.5)

  i
    display: inline-block
    color: inherit
    font-size: 1.8rem
    text-align: center
    vertical-align: middle
    user-select: none

div#scrollToTopButton:before
  content: ''
  display: inline-block
  vertical-align: middle
  height: 100%

@include media-breakpoint-only(xs)
  div#scrollToTopButton
    bottom: 20px
    right: 20px
</style>
