<template>
    <div>
        <div class="container page-photo-overview">
            <div class="row justify-content-center">
                <div class="col-6 col-lg-4 photo" v-for="(photo, index) in sortedPhotos" :key="index">
                  <router-link :to="goTo(photo)" :aria-label="goToDescription('photo', photo.title)">
                    <img :src="photo.src" :alt="photo.title">
                  </router-link>
                </div>
            </div>
            <div class="text-center mm-btn-photo-upload" v-if="!isHomepage() && page.visitor_upload">
                <div
                  class="btn btn-3"
                  role="link"
                  tabindex="0"
                  @click="$router.push({name: 'photo-upload', query: $route.params })"
                  data-cy="photos-add"
                >
                  {{ translate('photo_upload') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import goToDescriptionMixin from "../../mixins/goToDescriptionMixin"

  export default {
    name: "PhotoOverview",
    props: {
      photos: Array,
      sort: String,
      page: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    mixins: [
      goToDescriptionMixin
    ],
    computed: {
      sortedPhotos () {
        return this.photos.sort((a, b) => {
          if (this.sort === null) {
            return a.sequence < b.sequence ? -1 : 1
          }
          if (this.sort === 'oldnew') {
            return a.id < b.id ? -1 : 1
          }
          if (this.sort === 'newold') {
            return a.id < b.id ? 1 : -1
          }
          if (this.sort === 'name') {
            return a.filename < b.filename ? -1 : 1
          }
        })
      }
    },
    methods: {
      goTo(photo) {
        return {name: 'page', params: {pageId: photo.page_id, param1: photo.id}}
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.page-photo-overview
  .mm-btn-photo-upload
    margin-top: 30px
  .photo
    margin-bottom: $grid-gutter-width-base
    a
      color: inherit

    img
      width: 100%
      background-color: #cccccc
      cursor: pointer
      aspect-ratio: 3/2
      object-fit: cover

  @include media-breakpoint-down(lg)
    div[class^="col"]:nth-child(odd)
      padding-right: 5px

    div[class^="col"]:nth-child(even)
      padding-left: 5px

    div[class^="col"]:nth-last-child(1):nth-child(odd)
      padding: 0

    .photo
      margin-bottom: 10px
</style>
