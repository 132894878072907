<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";
import { useAppUi } from "@/stores/app-ui"

const appSurveyStore = useAppSurvey()
const appUiStore = useAppUi()

const { answerState } = storeToRefs(appSurveyStore)

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  }
})

onMounted(() => {
  const question = { ...props.question }

  answerState.value = question.answer ? parseInt(question.answer) : null
})

const npsOptions = ref([
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' }
])

const npsOptionsFirstGroup = computed(() => {
  return npsOptions.value.slice(0, 6)
})

const npsOptionsSecondGroup = computed(() => {
  return npsOptions.value.slice(6, 11)
})

const breakpoint = computed(() => {
  return appUiStore.breakingPoint.size
})

</script>

<template>
  <div>
    <div v-if="breakpoint > 2">
      <div class="d-flex justify-content-between">
        <div
          v-for="(value, index) in npsOptions"
          :key="index"
          class="d-flex flex-column align-items-center"
        >
          <label
            :for="value.label"
          >
            {{ value.label }}
          </label>
          <RadioButton
            v-model="answerState"
            :input-id="value.label"
            :name="value.label"
            :value="value.value"
            :data-cy="`nps-${value.value}`"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column"
    >
      <div class="col-12 d-flex justify-content-between">
        <div
          v-for="(value, index) in npsOptionsFirstGroup"
          :key="index"
          class="d-flex flex-column align-items-center"
        >
          <label
            :for="value.label"
          >
            {{ value.label }}
          </label>
          <RadioButton
            v-model="answerState"
            :input-id="value.label"
            :name="value.label"
            :value="value.value"
          />
        </div>
      </div>
      <div class="col-12 d-flex justify-content-between mt-4 px-4">
        <div
          v-for="(value, index) in npsOptionsSecondGroup"
          :key="index"
          class="d-flex flex-column align-items-center"
        >
          <label
            :for="value.label"
          >
            {{ value.label }}
          </label>
          <RadioButton
            v-model="answerState"
            :input-id="value.label"
            :name="value.label"
            :value="value.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>
