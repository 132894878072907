export default {
  title: {
    type: String,
    default: ''
  },
  isLogoCentered: {
    type: Boolean,
    default: false
  },
  isPasswordRequired: {
    type: Boolean,
    default: false
  },
  menuPosition: {
    type: String,
    default: 'left'
  }
}
