<template>
  <div>
    <!-- 8/4 for all pages (not logo centered) -->
    <div class="row align-items-center" v-if="!isLogoCentered">
      <div class="text-start col-lg-8">
        <div v-if="!isPasswordRequired" class="menu">
          <menu3 :menuPosition="menuPosition"></menu3>
        </div>
      </div>
      <div class="text-end align-self-center col-lg-4">
        <Logo></Logo>
      </div>
    </div>

    <!-- 8/4 for all pages (logo centered) -->
    <div class="row align-items-center" v-if="isLogoCentered">
      <div class="text-start col-lg-4">
        <div v-if="!isPasswordRequired" class="menu">
          <menu3 :menuPosition="menuPosition"></menu3>
        </div>
      </div>
      <div class="text-end align-self-center col-lg-4">
        <Logo></Logo>
      </div>
    </div>
  </div>
</template>

<script>
import Menu3 from "@/components/Menu/Menu3";
import Logo from "../../Logo";
import defaultProps from "../defaultProps";
export default {
  props: defaultProps,
  components: {
    Menu3,
    Logo
  }
};
</script>

<style lang="sass" scoped type="text/sass">
@import "../../Topbar.sass"
</style>
