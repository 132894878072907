<script setup>
import { onMounted } from 'vue'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";

const appSurveyStore = useAppSurvey()

const { answerState } = storeToRefs(appSurveyStore)

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  }
})

onMounted(() => {
  const question = { ...props.question }

  answerState.value = question.answer ?? null
})
</script>

<template>
  <div class="mm-custom-form">
    <div
      v-for="(option, index) in props.question.options"
      :key="index"
      class="form-check"
    >
      <input
        :id="'radio' + option.option_id"
        v-model="answerState"
        class="form-check-input"
        type="radio"
        :value="option.option_id"
        aria-describedby="survey-question-legend"
      >
      <label
        class="form-check-label paragraph"
        :for="'radio' + option.option_id"
      >
        {{ option.option }}
      </label>
    </div>
  </div>
</template>
