<template>
  <div>
    <form method="post" v-on:submit.prevent="emitCode()">
      <div class="row">
        <div class="col text-center">
          <label for="code">{{ label }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <input class="form-control" v-model="code" type="text" id="code" data-cy="input-code"/>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button class="btn btn-1" type="submit" data-cy="button-code">{{ buttonDescription }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// TODO: move to the Composition API when:
// - we have Vue 3 
// - we don't use the translate mixins
// - We don't use Vuex
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    buttonDescription: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      code: ''
    }
  },
  methods: {
    emitCode() {
      this.$emit('handleCode', this.code)
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
label
  display: inline-block
  font-weight: 600

input[type='text']
  display: inline-block
  max-width: 330px
  height: 56px
  margin-bottom: 65px
</style>