<template>
  <div class="chat-room-component">
    <div v-if="info.length > 0" class="chat-room-user-action" :style="`background-color: ${colors(6)}`">
      <p v-for="(user, index) in info" :key="index">
        {{ getUserActionTranslation(user.username,user.type) }}
      </p>
    </div>

    <div class="chat-room-content">
      <div class="chat-list-group list-group-flush text-start" ref="messages-list">
        <div class="chat-list-group-item" v-for="(message, index) in messages" :key="index">
          <p class="chat-list-group-item-user">{{ message.user }}</p>
          <p class="chat-list-group-item-message">{{ message.message }}</p>
        </div>
      </div>
    </div>
    <div class="chat-room-action" :style="`border-color: ${colors(5)}`">
      <form @submit.prevent="send" ref="form">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="newMessage"
            :placeholder="translate('chat_start_typing')"
            autocomplete="off"
          />
          <span v-on:click="submit" class="icon-arrow-right chat-room-action-sent" :title="translate('chat_send')"></span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { env } from "@/env";
import { io } from "socket.io-client"
import { mapStores } from "pinia"
import { useAppChat } from "@/stores/app-chat"

export default {
  props: { roomHash: String, user: Object, roomName: String},
  computed: {
    ...mapStores(useAppChat)
  },
  data() {
    return {
      newMessage: null,
      messages: [],
      typing: false,
      ready: false,
      info: [],
      connections: 0,
      socket: io(env.CHAT_SERVER + "/" + this.roomHash, {
        forceNew: true,
        secureConnection: true,
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        transports: ["websocket","polling"],
        query: {
          token: localStorage.getItem("x-momice-token"),
          eventUrl: window.location.hostname,
          hash: this.roomHash
        }
      })
    };
  },
  beforeUnmount() {
    this.socket.close();
  },
  created() {
    window.onbeforeunload = () => {
      this.socket.emit("leave", this.user.name);
    };

    this.socket.on("chat-message", data => {
      this.messages.push({
        message: data.message,
        type: 1,
        user: data.user
      });
    });

    this.socket.on("history", data => {
      data.forEach(element => {
        this.messages.push({
          message: element.message,
          type: 1,
          user: element.name
        });
      });
    });


    this.socket.on("connect", data => {
      this.socket.emit("joined", this.user.name);
    });

    this.socket.on("error", data => {
      this.appChatStore.setCurrentRoom(null)
      this.socket = null;
    });

    this.socket.on("connect_error", data => {
      this.appChatStore.setCurrentRoom(null)
      this.socket = null;
    });

    this.socket.on("reconnect_failed", data => {
      this.appChatStore.setCurrentRoom(null)
      this.socket = null;
    });

    this.socket.on("typing", data => {
      this.typing = data;
    });

    this.socket.on("stopTyping", () => {
      this.typing = false;
    });

    this.socket.on("joined", data => {
      this.info.push({
        username: data,
        type: "joined"
      });

      setTimeout(() => {
        this.info = [];
      }, 3000);
    });

    this.socket.on("leave", data => {
      this.info.push({
        username: data,
        type: "left"
      });

      setTimeout(() => {
        this.info = [];
      }, 3000);
    });

    this.socket.on("connections", data => {
      this.connections = data;
    });
  },

  watch: {
    newMessage(value) {
      value
        ? this.socket.emit("typing", this.user.name)
        : this.socket.emit("stopTyping");
    },
    messages() {
      var container = this.$refs["messages-list"];
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    }
  },

  methods: {
    send() {
      if(this.newMessage !== null) {
        this.messages.push({
          message: this.newMessage,
          type: 0,
          user: this.translate('chat_me')
        });
        this.socket.emit("chat-message", {
          message: this.newMessage,
          user: this.user.name
        });
        this.newMessage = null;
      }
    },
    submit : function(){
      this.send()
    },
    getUserActionTranslation(user,type) {
      return user +" "+this.translate('chat_user_action_'+type);
    }
  }
};
</script>

<style lang="sass" type="text/sass" scoped>
.chat-room-component
  height: 100%
  display: flex
  flex-direction: column
  justify-content: end

.chat-room-user-action
  position: absolute
  top: 0px
  left: 0px
  height: 53px
  padding-left: 20px
  line-height: 53px
  width: 100%
  padding-top: 12px
  border-bottom: 1px solid
  p
    padding: 0px
    margin: 0px
    font-style: italic

.chat-room-content
  height: calc(100% - 53px)
  .chat-list-group
    overflow-y: auto
    overflow-x: hidden
    height: 100%
    padding: 10px
    padding-left: 20px
    .chat-list-group-item
      border: none
      padding: 0
      padding-top: 15px
      background-color: transparent
      .chat-list-group-item-user
        font-size: 80%
        font-weight: bold
        font-family: inherit
        width: 100%
        margin-bottom: 0px
      .chat-list-group-item-message
        font-family: inherit
        width: 100%
        margin-bottom: 5px
        line-height: 1.3rem
        word-break: break-word

.chat-room-action
  border-top: 1px solid
  .form-group
    margin: 0
    .form-control
      width: 84%
      float: left
      height: 53px
      border: 0
      padding-left: 20px
      background-color: transparent
    .chat-room-action-sent
      width: 16%
      float: right
      height: 53px
      text-align: center
      font-size: 42px
      cursor: pointer
      line-height: 51px
    .form-control::-webkit-input-placeholder
      font-family: inherit


</style>
