<script setup>
import { onMounted } from 'vue'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";

const appSurveyStore = useAppSurvey()

const { answerState } = storeToRefs(appSurveyStore)

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  }
})

onMounted(() => {
  const question = { ...props.question }

  answerState.value = question.answer ?? null
})
</script>

<template>
  <div>
    <Rating
      id="survey-question"
      v-model="answerState"
      :stars="props.question.options.length"
      aria-labelledby="survey-question"
      :cancel="false"
    />
  </div>
</template>

