<script setup>
import { useTranslations } from '@/composables/translations'

const { translate } = useTranslations()

const props = defineProps({
  validated: {
    required: false,
    default: false,
    type: Boolean
  }
})
</script>

<template>
  <div
    v-if="!props.validated"
    class="survey-error"
    role="alert"
  >
    {{ translate('validation_required_2') }}
  </div>
</template>

<style scoped>
.survey-error {
  color: red;
  font-weight: 900;
  margin-top: -30px;
  margin-bottom: 20px;
}
</style>