<template>
  <div class="news-card footer">
    <div class="news-card" :class="newsFooterStyle">
  		<div class="news-card" :class="likesActive" @click="like(data.news_id,liked)" data-cy="new-like">
        <i class="icon-thumb-up" :class="likeStatus">a</i>
        <span class="num-likes">{{ total_likes }}</span>
      </div>
  		<div class="news-card comments">
        <i :class="commentStatus">T</i>
        <span class="num-comments">{{ total_comments }}</span>
      </div>
  		<div class="news-card date">{{ dateFormat(data.date) }}</div>
  	</div>
    <news-comment v-if="!isOverview" :data="data" @newComment="updateCounter"></news-comment>
  </div>
</template>

<script>
  import Moment from "moment"
  import http from '../../http'
  import Comment from "./NewsComment.vue"
  import { mapStores } from "pinia"
  import { useAppRegistration } from "@/stores/app-registration"
  import { useAppLounge } from "@/stores/app-lounge"

  export default {
		props: {
			data: Object,
      isOverview: Boolean,
      isWidget: Boolean
		},
    data() {
      return {
        liked: false,
        total_likes: 0,
        total_comments: 0
      }
    },
    created () {
      this.liked = this.data.like;
      this.total_likes = this.data.total_likes;
      this.total_comments = this.data.comments.length;
    },
		methods: {
			dateFormat(date) {
        return Moment(date).format('DD MMM');
      },
      like(news_id,like) {
        if(!this.isRegistration){
          return;
        }
        http.post('/news/like', { news_id: news_id, like: !like }).then((response) => {
          this.liked = response.data.like;
          this.total_likes = response.data.total_likes;
        }).catch(e => {
          console.log(e);
        })
      },
      updateCounter(tot) {
        this.total_comments = tot;
      }
		},
    computed: {
      ...mapStores(useAppRegistration, useAppLounge),
      isRegisteredNews() {
        return this.appRegistrationStore.isRegistered
      },
      isRegisteredLounge() {
        return this.appLoungeStore.isRegistration
      },
      likeStatus() {
        return this.liked === true ? 'news-card-liked' : 'news-card-default';
      },
      commentStatus() {
        return this.total_comments > 0 ? 'icon-message' : 'icon-message-none';
      },
      isRegistration() {
        return this.isWidget ? this.isRegisteredLounge : this.isRegisteredNews
      },
      likesActive() {
        return this.isRegistration === true ? 'likes' : 'likes-unactive'
      },
      newsFooterStyle() {
        return this.isWidget === true ? 'reaction-stats-widget' : 'reaction-stats'
      }
    },
    components: {
      'news-comment': Comment
    }
	}
</script>

<style lang="sass" type="text/sass" scoped>
.footer
  width: 100%
.reaction-stats
  border-top: 1px solid rgba(0, 0, 0, 0.12)
.reaction-stats, .reaction-stats-widget
  .comments
    float: left
    padding: 16px
    padding-left: 0px
    .icon-message
      float: left
      margin-top: 4px
    .icon-message-none
      float: left
      color: rgba(0, 0, 0, 0.12)
      margin-top: 4px
    .num-comments
      float: left
      margin-left: 5px
  .likes
    cursor: pointer
  .likes, .likes-unactive
    float: left
    padding: 16px
    .num-likes
      float: left
      margin-left: 5px
    .news-card-liked
      float: left
    .news-card-default
      color: rgba(0, 0, 0, 0.12)
      float: left
  .date
    float: right
    padding: 16px

</style>
