<template>
  <div>
    <!-- 8/4 for not logo centered -->
    <div v-if="!isLogoCentered" class="row align-items-center">
      <div class="text-start align-self-center col-8">
        <Logo></Logo>
      </div>
      <div class="text-end col-4">
        <div v-if="!isPasswordRequired" class="menu">
          <menu3 :menuPosition="menuPosition"></menu3>
        </div>
      </div>
    </div>

    <!-- 2/8/2 for logo is centered -->
    <div v-if="isLogoCentered" class="row align-items-center">
      <div class="col-2"></div>
      <div class="align-self-center col-8">
        <Logo></Logo>
      </div>
      <div class="text-end col-2">
        <div v-if="!isPasswordRequired" class="menu">
          <menu3 :menuPosition="menuPosition"></menu3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu3 from "@/components/Menu/Menu3";
import Logo from "../../Logo";
import defaultProps from "../defaultProps";
export default {
  props: defaultProps,
  components: {
    Menu3,
    Logo
  }
};
</script>

<style lang="sass" scoped type="text/sass">
@import "../../Topbar.sass"
</style>
