<template>
  <div>
    <Menu1 class="d-lg-none" />
    <div 
      id="menu-3"
      class="d-none d-lg-block"
    >
      <router-link
        v-if="isRegisterPageActive && isRegisterActive && menuActive"
        v-slot="{ navigate }"
        to="/register"
        custom
      >
        <button
          role="link"
          class="btn btn-3 d-none d-lg-inline btn-menu-3"
          tabindex="0"
          @click="navigate"
          @keypress.enter="navigate"
        >
          {{ translate('register_call_to_action') }}
        </button>
      </router-link>
      <div
        v-if="menuActive"
        class="btn btn-1 d-none d-lg-inline btn-menu-3"
        role="link"
        tabindex="0"
        @keypress.enter="mouseEnterMore"
        @mouseenter="mouseEnterMore"
        @mouseleave="mouseLeaveMore"
      >
        Menu
        <more 
          v-if="isMenuOpen"
          :pages="pages"
          :type="3"
        />
      </div>
      <LanguageDropdown />
    </div>
  </div>
</template>

<script>
  import Menu1 from './Menu1'
  import More from './Partials/More'
  import LanguageDropdown from './Menu3/LanguageDropdown'
  import menuMixin from "../../mixins/menuMixin"
  import { mapStores } from 'pinia'
  import { useAppLounge } from '@/stores/app-lounge'
  import { useAppTemplate } from '@/stores/app-template'
  import { useAppPages } from '@/stores/app-pages'
  import { useAppMenu } from '@/stores/app-menu'
  import { useApp } from '@/stores/app'

  export default {
    components: {
      Menu1,
      More,
      LanguageDropdown
    },
    mixins: [
      menuMixin
    ],
    data () {
      return {}
    },
    computed: {
      ...mapStores(useApp, useAppLounge, useAppTemplate, useAppPages, useAppMenu),
      pages() {
        return this.appPagesStore.menu
      },
      showHomepage() {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      showLounge() {
        return this.appLoungeStore.active
      },
      template() {
        return this.appTemplateStore.template
      },
      currentPages() {
        return this.appPagesStore.currentLanguage
      },
      event() {
        return this.appStore.event
      },
      isRegisterPageActive() {
        return this.currentPages.filter(p => p.type === 'register' && p.active === 'yes').length > 0
      },
      isRegisterActive() {
        return this.event.registration
      },
      menuActive() {
        return this.pages.length > 1 || this.showHomepage || this.showLounge
      },
      isMenuOpen() {
        return this.appMenuStore.open
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.btn
  position: relative
  line-height: 1.25

.btn-menu-3
  margin-right: 4px
</style>
