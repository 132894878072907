<template>
  <component :is="stream.platform + 'Component'" :stream_data="stream.platform_data" ></component>
</template>
<style lang="sass" type="text/sass">
.stream-embed
  position: relative
  width: 100%
  height: 100%
</style>
<script>
  import Other from './Platforms/Other.vue'
  import Youtube from './Platforms/Youtube.vue'
  import Vimeo from './Platforms/Vimeo.vue'

  export default {
    props: {
      stream: Object
    },
    computed: {

    },
    components: {
      'otherComponent': Other,
      'youtubeComponent': Youtube,
      'vimeoComponent': Vimeo,
    },
    methods: {

    }
  }
</script>
