<template>
  <a href="#router-view" id="skip-to-content" class="btn btn-1 position-absolute">{{ translate('skip_to_content') }}</a>
</template>

<script>

export default {
  name: 'SkipToContent'
}
</script>

<style lang="sass" type="text/sass" scoped>
#skip-to-content
  top: -9999px
  left: -9999px
  z-index: 1000000
  &:focus-visible
    top: 27px
    left: 27px
</style>
