<template>
  <div class="container inner">
    <div class="justify-content-center">
      <div
        v-for="(n, i) in news"
        :key="i"
        class="card-container"
        data-cy="new-card"
      >
        <router-link
          class="mm-color-text text-center no-underline"
          :to="{ name: 'news', params: { newsId: n.news_id }}"
        >
          <news-header
            :data="n"
            :title="n.introduction"
          />

          <!-- regular post card -->
          <div
            v-if="n.type == 'regular'"
            class="news-card body"
            :style="{ backgroundImage: 'url('+cardCover(n)+')' }"
          />
          <!-- podcast and video cards -->
          <div v-if="n.type == 'podcast' || n.type == 'video'">
            <div
              class="news-card body"
              :style="{ backgroundImage: 'url('+cardCover(n)+')' }"
            />
            <div class="news-card title">
              {{ n.extra.title }}
            </div>
          </div>
          <!-- poll card -->
          <div
            v-if="n.type == 'poll'"
            class="news-card poll-card"
          >
            <div class="row justify-content-center poll-vote-btn">
              <div
                class="col-12"
                style="margin-bottom: 5px;"
              >
                <b v-if="n.extra.visitor_vote !== null">{{ getVote(n.extra.options,n.extra.visitor_vote.option_id) }}</b>
              </div>
              <button
                v-if="n.extra.visitor_vote !== null"
                type="button"
                class="btn btn-1 poll-vote-btn"
              >
                View results
              </button>
              <button
                v-else
                type="button"
                class="btn btn-1 poll-vote-btn"
              >
                {{ n.extra.vote_button_text }}
              </button>
            </div>
          </div>
        </router-link>
        <news-footer
          :data="n"
          :isOverview="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
	import Header from "./NewsHeader.vue"
	import Footer from "./NewsFooter.vue"

	export default {
		components: {
			'news-header': Header,
			'news-footer': Footer
		},
		props: {
			news: {
        type: Array,
        default: () => []
      }
		},
		methods: {
			getVote(options,vote_option_id) {
        return options.find(p => p.option_id === vote_option_id).name
      },
      cardCover (data) {
        // Check if news post has an image
        if (data.image && data.image != '') {
          return data.image
        } else if (data.extra && data.extra.image && data.extra.image != '') {
          return data.extra.image
        }

        // Fallbacks
        if(data.type == 'podcast' || data.type == 'regular') {
          return "https://tool.momice.com/include/momice/defaults/images/news_page_type_"+data.type+".png";
        } else if(data.type == 'video') {
          switch (data.extra.type) {
            case 'youtube':
              return `https://img.youtube.com/vi/${data.extra.value}/0.jpg`
            case 'vimeo':
              try {
                let numberPattern = /\d+/g
                let numbers = data.extra.value.match(numberPattern)
                return "https://vumbnail.com/" + numbers[0] + ".jpg"
              } catch (err) {
                console.log(err)
              }
          }
        }
      }
		},
	}
</script>


<style lang="sass" type="text/sass" scoped>
.card-container
  width: 100%
  margin: 20px 0px
  display: flex
  position: relative
  border-radius: 6px
  flex-direction: column
  border: 1px solid rgba(0, 0, 0, 0.12)
  a
    color: inherit

.body
  height: 160px
  min-height: 160px
  display: flex
  background: #484b4d
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  width: 100%
  pointer-events: none

.title
  padding: 15px
  text-align: left

.poll-card
  height: 160px
  min-height: 160px
  text-decoration: none

.poll-vote-btn
  clear: both
  min-width: 250px

.no-underline
  text-decoration: none

</style>