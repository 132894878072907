<script setup>

import ImageHeader from "./ImageHeader.vue";

const props = defineProps({
  image: {
    type: String,
    default: null
  }
})
</script>

<template>
  <div class="page-flex mm-page" :class="{ 'has-image': props.image }">
    <ImageHeader :imageUrl="props.image"></ImageHeader>
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<style  lang="sass" type="text/sass" scoped>
.mm-page.has-image
  padding-top: 0 !important
</style>
