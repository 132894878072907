<script setup>
import { computed } from 'vue'
import QuestionAnswer from './QuestionAnswer'

const props = defineProps({
  workshops: {
    default: [],
    type: Array
  }
})

const questionsAnswers = computed(() => {
  return [
    ...props.workshops.filter(workshop => workshop.linkedToProgram),
    ...props.workshops.filter(workshop => !workshop.linkedToProgram)
  ]
})
</script>

<template>
  <div>
    <QuestionAnswer
      v-for="(workshop, index) in questionsAnswers"
      :key="index"
      :workshop="workshop"
    ></QuestionAnswer>
  </div>
</template>

<style scoped type="text/sass" lang="sass">

</style>