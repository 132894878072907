<template>
  <div class="chat-user-info">
    <div class="chat-room-content"></div>
    <div class="chat-room-action" :style="`border-color: ${colors(5)}`">
      <form @submit.prevent="addUser" ref="form">
        <div class="form-group">
          <input
            required
            type="text"
            class="form-control"
            v-model="userName"
            :placeholder="translate('chat_choose_username')"
            autocomplete="off"
          />
          <span v-on:click="submit" class="icon-arrow-right chat-room-action-sent" :title="translate('chat_choose_username_join')"></span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useVisitor } from "@/stores/visitor"
import { useAppChat } from "@/stores/app-chat"

export default {
  props: {},
  computed: {
    ...mapStores(useVisitor, useAppChat)
  },
  data() {
    return {
      userName: null
    };
  },
  created() {
    const invite = this.visitorStore.invite
    if (invite.name) {
      this.appChatStore.setCurrentUser({ name: invite.name })
    }
  },
  methods: {
    addUser() {
      if(this.userName !== undefined) {
        this.appChatStore.setCurrentUser({ name: this.userName })
      }
    },
    submit : function(){
      this.addUser()
    }
  }
};
</script>

<style lang="sass" tpe="text/sass" scoped>
.chat-user-info
  height: 100%
  display: flex
  flex-direction: column
  justify-content: end

  .chat-room-action
    border-top: 1px solid
    .form-group
      margin: 0
      .form-control
        width: 84%
        float: left
        height: 53px
        border: 0
        padding-left: 20px
        background-color: transparent
      .chat-room-action-sent
        width: 16%
        float: right
        height: 53px
        text-align: center
        font-size: 42px
        cursor: pointer
        line-height: 51px
</style>
