<template>
  <div>
    <div
      v-if="logoImg"
      id="logo"
      class="d-block"
      :class="{ 'logo-align-top': noPaddingTop }"
    >
      <router-link
        :to="{name: goTo }"
        :title="titleLogoLink"
      >
        <img
          class="logo-img"
          :alt="logoAlt"
          :src="logoImg"
        >
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppTemplate } from '@/stores/app-template'
  import { useAppLounge } from '@/stores/app-lounge'

  export default {
    data () {
      return {}
    },
    computed: {
      ...mapStores(useAppTemplate, useAppLounge),
      topbar () {
        return this.appTemplateStore.get('topbar')
      },
      noPaddingTop() {
        if (this.topbar && this.topbar.logo_no_padding_top) {
          return true
        }
        return false
      },
      goTo() {
        return this.appLoungeStore.active ? 'lounge' : 'home'
      },
      titleLogoLink() {
        return this.translate('go_to_home', { home: this.goTo })
      },
      logoImg() {
        return this.appTemplateStore.get('start_logo')
      },
      logoAlt() {
        return this.topbar.logo_alt
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div#logo
  cursor: pointer
  width: 100%

@include media-breakpoint-up(lg)
  div#logo
    height: ($topbar-height-large)-26px
  .logo-img
    max-height: ($topbar-height-large)-26px
@include media-breakpoint-down(lg)
  div#logo
    height: ($topbar-height-small)-20px
  .logo-img
    max-height: ($topbar-height-small)-20px
#logo.logo-align-top
  height: 90px !important
  background-position-y: top !important
  background-size: auto 76px !important

i
  cursor: pointer
</style>
