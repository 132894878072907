<script setup>
import { computed } from 'vue'

const props = defineProps({
  workshop: {
    default: {},
    type: Object
  }
})

const showTime = computed(() => {
  return props.workshop ? props.workshop.linkedToProgram : false
})
</script>

<template>
  <div v-if="workshop" class="py-2">
    <div class="d-flex">
      <div v-if="showTime" class="time">
        <span class="fw-bold">{{ workshop.start_time }} - </span>
        <span>{{ workshop.end_time }}</span>
      </div>
      <div v-html="workshop.description" class="fw-bold"></div>
    </div>
    <div class="d-flex">
      <div v-if="showTime" class="time"></div>
      <div v-html="workshop.value"></div>
    </div>
  </div>
</template>

<style scoped type="text/sass" lang="sass">
.time 
  width: 120px

</style>
