<template>
  <div class="container inner">
    <div class="news-card tab-filter">
    	<div class="justify-content-center">
				<nav>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist" :style="`color: ${colors(7)}`">
						<a :class="'nav-item nav-link ' + (category === 'all' ? 'active' : '')" @click="filter('all')" data-toggle="tab" role="tab" data-cy="all">{{ translate('mail_start_all') }}</a>
						<a :class="'nav-item nav-link ' + (category === 'video' ? 'active' : '')" @click="filter('video')" data-toggle="tab" role="tab" data-cy="video"><i class="icon-video"></i></a>
						<a :class="'nav-item nav-link ' + (category === 'regular' ? 'active' : '')" @click="filter('regular')" data-toggle="tab" role="tab" data-cy="regular"><i class="icon-menu"></i></a>
						<a :class="'nav-item nav-link ' + (category === 'podcast' ? 'active' : '')" @click="filter('podcast')" data-toggle="tab" role="tab" data-cy="podcast"><i class="icon-speakers"></i></a>
					</div>
				</nav>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
  background-color: transparent
  border-color: transparent transparent #f3f3f3
  font-size: 20px
  font-weight: bold
  color: inherit

.nav-tabs
  .nav-link
    border: 1px solid transparent
    border-top-left-radius: .25rem
    border-top-right-radius: .25rem
    font-size: 20px
    color: inherit
    cursor: pointer
    &:focus,&:hover
      border: none
    i
      color: inherit

</style>
<script>
  export default {
    props: {
      filt: String
    },
    data () {
      return {
        category: this.filt,
      }
    },
    methods: {
      filter(c) {
        this.category = c;
        this.$emit("filterData", c);
      }
    }
  }
</script>
