<template>
  <div class="menu">
    <router-link
      v-if="showHomepage"
      v-slot="{ navigate }"
      :to="{ name: 'home'}"
      custom
    >
      <div
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <router-link
      v-if="showLounge"
      v-slot="{ navigate }"
      :to="{ name: 'lounge'}"
      custom
    >
      <div
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <template v-for="(item, index) in pages">
      <div
        v-if="item.type == 'external'"
        :key="item.page_id"
        class="item"
        @click="gotoExt(item.extra)"
      >
        <div class="borders" />
        <i
          v-if="item.icon"
          :class="item.icon"
          aria-hidden="true"
        />
        <i
          v-else
          class="icon-home placeholder"
          aria-hidden="true"
        />
        <span>{{ item.title }}</span>
      </div>
      <router-link
        v-else
        v-slot="{ navigate }"
        :key="index"
        :to="{ name: 'page', params: { pageId: item.page_id }}"
        custom
      >
        <div
          class="item"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <div class="borders" />
          <i
            v-if="item.icon"
            :class="item.icon"
            aria-hidden="true"
          />
          <i
            v-else
            class="icon-home placeholder"
            aria-hidden="true"
          />
          <span>{{ item.title }}</span>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppLounge } from '@/stores/app-lounge'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    name: 'Style4',
    props: {
      pages: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapStores(useAppLounge, useAppTemplate),
      showHomepage() {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      showLounge() {
        return this.appLoungeStore.active
      },
      template() {
        return this.appTemplateStore.template
      }
    },
    methods: {
        gotoExt(extra) {
          window.open(JSON.parse(extra).link);
        }
    }
  }
</script>

<style type="text/scss" lang="scss" scoped>
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        .item {
            display: flex;
            flex-direction: row;
            width: 70%;
            border: 0;
            align-items: center;
            padding: 9px;
            justify-content: center;

            &:not(:last-of-type) {
                border-bottom: 1px solid;
            }

            span {
                padding: 0 10px;
                font-size: 1rem;
                line-height: 1.2rem;
                text-align: center;
            }
        }
    }
</style>
