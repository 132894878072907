<template>
  <div :class="topbarTypeClasses" data-cy="topbar">
    <div id="topbar">
      <div class="topbar px-3" data-cy="topbar-container">
        <component
          :is="activeComponent"
          :title="title"
          :isPasswordRequired="isPasswordRequired"
          :isLogoCentered="isLogoCentered"
          :menuPosition="menuPosition"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import Menu1LeftDesktop from "@/components/Topbar/variations/Menu1/LeftDesktop";
import Menu1LeftMobile from "@/components/Topbar/variations/Menu1/LeftMobile";
import Menu1RightDesktop from "@/components/Topbar/variations/Menu1/RightDesktop";
import Menu1RightMobile from "@/components/Topbar/variations/Menu1/RightMobile";
import Menu2LeftDesktop from "@/components/Topbar/variations/Menu2/LeftDesktop";
import Menu2LeftMobile from "@/components/Topbar/variations/Menu2/LeftMobile";
import Menu2RightDesktop from "@/components/Topbar/variations/Menu2/RightDesktop";
import Menu2RightMobile from "@/components/Topbar/variations/Menu2/RightMobile";
import Menu3LeftDesktop from "@/components/Topbar/variations/Menu3/LeftDesktop";
import Menu3LeftMobile from "@/components/Topbar/variations/Menu3/LeftMobile";
import Menu3RightDesktop from "@/components/Topbar/variations/Menu3/RightDesktop";
import Menu3RightMobile from "@/components/Topbar/variations/Menu3/RightMobile";
import { PASSWORD_REQUIRED } from "@/stores/statusses";
import { mapStores } from "pinia"
import { useApp } from "@/stores/app"
import { useAppPages } from "@/stores/app-pages"
import { useAppUi } from "@/stores/app-ui"
import { useAppTemplate } from "@/stores/app-template"

export default {
  components: {
    Menu1LeftDesktop,
    Menu1LeftMobile,
    Menu1RightDesktop,
    Menu1RightMobile,
    Menu2LeftDesktop,
    Menu2LeftMobile,
    Menu2RightDesktop,
    Menu2RightMobile,
    Menu3LeftDesktop,
    Menu3LeftMobile,
    Menu3RightDesktop,
    Menu3RightMobile
  },
  computed: {
    ...mapStores(useApp, useAppPages, useAppUi, useAppTemplate),
    activeComponent() {
      let menu = `Menu${this.menu}`;
      let device = "Desktop";
      let side = "Left";
      if (this.breakpoint.size <= 3) {
        device = "Mobile";
      }
      if (this.menuPosition === "right") {
        side = "Right";
      }
      return `${menu}${side}${device}`;
    },
    currentPage() {
      return this.appPagesStore.current
    },
    title: function() {
      if (this.currentPage) {
        return this.currentPage.title;
      } else if (this.$route.name === "styleguide") {
        return this.translate("font_h1");
      }
      return "";
    },
    menu() {
      return this.appTemplateStore.get("menu.type")
    },
    breakpoint() {
      return this.appUiStore.breakingPoint
    },
    isHome() {
      return this.$route.name === "home";
    },
    isPasswordRequired() {
      return this.appStore.status === PASSWORD_REQUIRED;
    },
    menuPosition() {
      return this.appTemplateStore.get("menu.position");
    },
    topbar() {
      return this.appTemplateStore.get("topbar");
    },
    isLeft() {
      return this.menuPosition === "left";
    },
    isRight() {
      return this.menuPosition === "right";
    },
    isLogoCentered() {
      if (this.topbar && this.topbar.logo_align_center) {
        return this.topbar.logo_align_center;
      }
      return false;
    },
    topbarTypeClasses() {
      let classes = [`breakpoint_${this.breakpoint.className}`];
      if (this.isLeft) {
        classes.push("isLeft");
      }
      if (this.isRight) {
        classes.push("isRight");
      }
      if (this.isLogoCentered) {
        classes.push("isLogoCentered");
      }
      if (this.isHome) {
        classes.push("isHome");
      }
      return classes.join(" ");
    }
  }
};
</script>
<style lang="sass" scoped type="text/sass">
@import "./Topbar.sass"
</style>
