<template>
  <div class="stream-embed" ref="streamEmbed" :style="'height: '+this.streamHeight+'px; width: '+this.streamWidth+'px'">
    <div class=" stream-embed-vimeo" ref="vimeo-player" id="vimeo-player" data-vimeo-width="640"></div>
  </div>
</template>
<style lang="sass" type="text/sass">
.stream-embed
  margin: 0 auto

  .stream-embed-vimeo
    position: relative
    width: 100%
    height: 100%
    padding: 100px
    @media (max-height: 1024px)
      padding: 20px
    @include media-breakpoint-down(lg)
      padding: 0px

    iframe
      max-height: 100%
      max-width: 100%
      height: 100vh
      width: 100vw
      @include media-breakpoint-down(lg)
        height: 100%
        width: 100%
</style>
<script>
  import Vimeo from '@vimeo/player'
  import { mapStores } from 'pinia'
  import { useAppUi } from '@/stores/app-ui'

  export default {
    props: {
      stream_data: Object,
      stream_height: 480,
      stream_width: 640,
    },
    data () {
      return {
        streamHeight: null,
        streamWidth: null
      }
    },
    created() {
      this.streamHeight = this.stream_height
      this.streamWidth = this.stream_width
    },
    methods: {
      initVideo () {
        this.$nextTick(() => {
          if (this.stream_data.platform_hash !== '') {
            let options = {
              url: 'https://vimeo.com/'+this.stream_data.platform_hash,
              dnt: this.nocookies
            }
            /* eslint-disable no-new */
            new Vimeo('vimeo-player', options)
          }
        })
      },
      setDimensions() {
        var height = this.$refs.streamEmbed.clientHeight;
        var width = this.$refs.streamEmbed.clientWidth;
        if(this.device == 'desktop'){
          if(width > height) {
            if(height * 1.78 < width) {
              this.streamHeight = height;
              this.streamWidth = height * 1.78;
            } else {
              this.streamHeight = width / 1.78;
              this.streamWidth = width;
            }
          } else {
            this.streamHeight = width / 0.56;
            this.streamWidth = width;
          }
        }else {
          this.streamHeight = width * 0.56;
          this.streamWidth = width;
        }
      }
    },
    computed: {
      ...mapStores(useAppUi),
      device() {
        const breakPoint = this.appUiStore.breakingPoint
        return breakPoint.size <= 3 ? 'mobile' : 'desktop';
      },
      nocookies() {
        return this.stream_data.nocookies == 'yes';
      }
    },
    watch: {
      '$route.path' () {
        this.initVideo()
      }
    },
    mounted () {
      this.setDimensions();
      this.initVideo()
    }
  }
</script>
