<template>
  <div>
    <div
      class="container"
      :class="isHomepageClass"
    >
      <div
        v-for="(chunk, index) in chunkedVenues"
        :key="index"
        class="row justify-content-center"
      >
        <div
          v-for="(venue, indexVenue) in chunk"
          :key="indexVenue"
          class="col-12 col-md-6 col-lg-4 location"
        >
          <div
            v-if="venue.image"
            class="venue-image-container"
          >
            <router-link
              :to="goTo(venue)"
              :aria-label="goToDescription('venue', venue.title)"
            >
              <img
                :src="venue.image"
                class="image"
                :alt="venue.title"
              >
            </router-link>
          </div>
          <div
            class="details"
            :class="{hasImage: venue.image}"
            :style="detailStyle(venue)"
          >
            <h2 class="text-center">
              {{ venue.title }}
            </h2>
            <div class="text-center">
              {{ venue.address }}<span v-if="venue.zip">, </span>{{ venue.zip }}<br>
              {{ venue.city }}<span v-if="venue.country">, </span>{{ venue.country }}<br>
              {{ venue.telephone }}<br>
              <div
                v-if="venue.website"
                class="text-center"
              >
                <a
                  :href="venue.website"
                  target="_blank"
                >{{ getRootDomainFromUrl(venue.website) }}</a>
              </div>
              <div
                v-if="venue.email"
                class="text-center"
              >
                <a :href="`mailto:${venue.email}`">{{ venue.email }}</a>
              </div>
            </div>
          </div>
          <!-- TODO: trate -->
          <router-link
            v-if="hasMoreInfo(venue)"
            :aria-label="goToDescription('venue', venue.title)"
            class="btn btn-1"
            :to="goTo(venue)"
          >
            {{
              translate('more_information') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import chunk from 'lodash/chunk'
  import urlfuncs from '../../mixins/urlfuncs'
  import goToDescriptionMixin from "../../mixins/goToDescriptionMixin"

  export default {
    mixins: [
      urlfuncs,
      goToDescriptionMixin
    ],
    props: {
      venues: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      /**
       * Bepalen hoe de weergave wordt. Dit doen we aan de hand van het aantal venues
       * delen van het aantal venues door 3.
       * Bij een rest van 1 tonen we ze per 2
       * Bij een rest van 2 tonen we ze per 3
       */
      indexView () {
        let length = this.venues.length

        if (length === 2) {
          return 2
        }

        let modulo = length % 3

        if (modulo === 2 || modulo === 0) {
          return 3
        }

        return 2
      },
      chunkedVenues () {
        let chunkAmount = this.indexView

        return chunk(this.venues, chunkAmount)
      }
    },
    methods: {
      hasMoreInfo(venue) {
        return (venue.subpages && venue.subpages.length > 0) || venue.content != ""
      },
      detailStyle (venue) {
        let {colors} = this
        let border = `1px solid ${colors['5']}`
        let style = {
          borderLeft: border,
          borderRight: border,
          backgroundColor: colors['6']
        }
        if (!venue.image) {
          style.borderTop = border
        }
        if(!this.hasMoreInfo(venue)) {
          style.borderBottom = border
        }

        return style
      },
      goTo(venue) {
        return { name: 'page', params: { pageId: venue.parent_id, param1: venue.id }}
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.location
  margin-bottom: 40px
  display: flex
  align-content: space-between
  flex-direction: column

  .image
    width: 100%
    height: 100%
    position: relative
    object-fit: cover
    aspect-ratio: 1/0.71
    cursor: pointer

  h2
    font-weight: 600
    margin: 0
    overflow-wrap: break-word

  .details
    padding: 30px 25px
    &.hasImage
      flex-grow: 1

  div
    margin-bottom: 0px

    a
      color: inherit
      font-weight: 600
      text-decoration: underline

  .btn-1
    width: 100%

</style>
