<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  import QrcodeVue from 'qrcode.vue'

  const props = defineProps({
    value: {
      type: [String, Number],
      required: true
    },
    size: {
      type: Number,
      default: 290,
      required: false
    },
    level: {
      type: String,
      default: 'L',
      required: false
    },
    background: {
      type: String,
      default: '#FFFFFF',
      required: false
    },
    margin: {
      type: Number,
      default: 0,
      required: false
    },
  })

  const qrSize = ref(props.size)

  const updateSize = () => {
    const qr = document.querySelector('.qr')
    if (qr) {
      const style = getComputedStyle(qr)
      const paddingLeft = parseFloat(style.paddingLeft)
      const paddingRight = parseFloat(style.paddingRight)
      const size = qr.clientWidth - (paddingLeft + paddingRight)
      qrSize.value = size
    }
  }

  onMounted(() => {
    updateSize()
    window.addEventListener('resize', updateSize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateSize)
  })
</script>

<template>
  <div class='qr'>
    <qrcode-vue
      :value="props.value"
      :size="qrSize"
      :level="props.level"
      :background="props.background"
      :margin="props.margin"
      render-as="svg"
      class="image-fluid"
    />
  </div>
</template>

<style lang="scss" type="text/scss" scoped>
.qr {
  background-color: #FFFFFF;
  padding: 20px;
  border: 1px solid #CCCCCC;
  text-align: center;
}
</style>
