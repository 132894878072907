<script setup>
import { inject } from 'vue'
import ImageOrInitials from '@/components/ImageOrInitials.vue'

const service = inject('service')

const props = defineProps({
  resourceElement: {
    type: Object,
    default: () => {}
  }
})
</script>

<template>
  <div class="details col-8 order-1 order-md-2 col-md-12 text-start text-md-center">
    <div>
      <h3>
        <router-link
          class="mm-color-text"
          :to="{ name: 'page', params: { pageId: props.resourceElement.parent_id, param1: props.resourceElement.id }}"
        >
          {{ props.resourceElement.name }}
        </router-link>
      </h3>
    </div>
    <p class="mm-color-text">
      {{ props.resourceElement.function }}<span v-if="props.resourceElement.function && props.resourceElement.company">, </span>{{ props.resourceElement.company }}
    </p>
  </div>
  <div class="col-4 order-2 order-md-1 col-md-12">
    <ImageOrInitials
      :initials="props.resourceElement.initials"
      :to="{ name: 'page', params: { pageId: props.resourceElement.parent_id, param1: props.resourceElement.id }}"
      :image="props.resourceElement.image"
      :circle="props.resourceElement.circle"
      :resource="{ type: service, details: props.resourceElement.name }"
    />
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.details
  margin-top: 20px

  p
    margin: 0
    line-height: 1.3rem

  h3
    cursor: pointer
    margin-bottom: 5px
    line-height: 1.3rem
    font-weight: 600
    text-decoration: none

  .mm-color-text
    overflow-wrap: break-word
    hyphens: auto

@include media-breakpoint-down(md)
  .details
    margin-top: 0
    padding-right: 20px

  h3
    font-weight: 600
    cursor: pointer
    text-decoration: none

  p
    display: inline-block
</style>