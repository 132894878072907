<template>
  <div class="one-per-row">
    <router-link 
      v-if="showHomepage"
      v-slot="{ navigate }"
      :to="{ name: 'home'}"
      custom
    >
      <div 
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i 
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <router-link 
      v-if="showLounge"
      v-slot="{ navigate }"
      :to="{ name: 'lounge'}"
      custom
    >
      <div 
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <template v-for="(item, index) in pages">
      <div
        v-if="item.type == 'external'"
        :key="item.page_id"
        class="item"
        @click="gotoExt(item.extra)"
      >
        <div class="borders" />
        <i 
          v-if="item.icon"
          :class="item.icon"
          aria-hidden="true"
        />
        <i 
          v-else 
          class="icon-home placeholder"
          aria-hidden="true"
        />
        <span>{{ item.title }}</span>
      </div>
      <router-link
        v-else
        :key="index"
        v-slot="{ navigate }"
        :to="{ name: 'page', params: { pageId: item.page_id }}"
        custom
      >
        <div 
          class="item"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <div class="borders" />
          <i 
            v-if="item.icon" 
            :class="item.icon" 
            aria-hidden="true" 
          />
          <i 
            v-else 
            class="icon-home placeholder"
            aria-hidden="true" 
          />
          <span>{{ item.title }}</span>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppLounge } from '@/stores/app-lounge'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    name: 'Style1',
    props: {
      pages: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapStores(useAppLounge, useAppTemplate),
      showLounge () {
        return this.appLoungeStore.active
      },
      showHomepage () {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      template() {
        return this.appTemplateStore.template
      }
    },
    methods: {
        gotoExt(extra) {
          window.open(JSON.parse(extra).link);
        }
    },
  }
</script>

<style type="text/sass" lang="sass" scoped>
$menuBorderSize: 100%
$menuBorderOffset: 0%

.one-per-row
    width: 50%
    margin-left: 25%

.item
    padding: 0
    display: inline-flex
    align-items: start
    justify-content: center
    width: 100%
    margin: 0
    box-sizing: border-box
    border: 0
    position: relative
    cursor: pointer
    width: 50vw
    height: 50vw
    [class^="icon-"], [class*=" icon-"]
        font-size: 4.5rem
        font-style: normal
        margin-top: 15px

        &.placeholder
            visibility: hidden
    span
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        padding: 5%
        text-align: center
        font-size: 1.2rem
        line-height: 1.2
        padding-bottom: 15px

    &:not(:last-of-type)
        .borders
            position: absolute
            top: 0
            left: 0
            height: 100%
            width: 100%

            &:before
                content: ''
                position: absolute
                left: 0
                bottom: 0
                width: 100%
                height: 1px

.item:after
    content: ''
    display: block
    padding-bottom: 100%
</style>
