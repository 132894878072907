<template>
    <div class="video-wrapper video-wrapper-16-9">
      <div ref="youtube-player" id="youtube-player" v-if="video.type === 'youtube'"></div>
      <div ref="vimeo-player" id="vimeo-player" v-if="video.type === 'vimeo'" :data-vimeo-id="video.value"></div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.video-wrapper-16-9
  position: relative
  padding-bottom: 56.25% /* 16:9 */
  padding-top: 25px
  height: 0

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.page-video-detail
  h3
    font-weight: 600
</style>
<script>
  import YouTubePlayer from 'youtube-player'
  import Vimeo from '@vimeo/player'
  export default {
    props: {
      video: Object
    },
    methods: {
      initVideo () {
        this.$nextTick(() => {
          if (this.video) {
            if (this.video.type === 'youtube' && this.$refs['youtube-player']) {
              if (this.ytPlayer) {
                this.ytPlayer.destroy()
              }

              this.ytPlayer = YouTubePlayer('youtube-player', {
                videoId: this.video.value,
                playerVars: {
                  'rel': 0,
                  'disablekb': 1
                }
              })
            }

            if (this.video.type === 'vimeo') {
              let options = {
                id: this.video.value
              }

              /* eslint-disable no-new */
              new Vimeo('vimeo-player', options)
            }
          }
        })
      }
    },
    mounted() {
      this.initVideo()
    }
  }
</script>
