<template>
  <div v-if="imageUrl" class="image" :style="{ backgroundImage: 'url(\''+imageUrl+'\')', overflow:'hidden' }">
    <img :src="imageUrl" role="presentation" alt="">
  </div>
</template>

<script>

export default {
  props: {
    imageUrl: String
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
.image
  width: 100%
  max-height: 550px
  background-size: cover
  background-position: center center
  img
    max-width: 100%
    opacity: 0
</style>
