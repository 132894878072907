<template>
    <div class="bg">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <img src="/static/img/momice-logo.svg" alt="Momice B.V." />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <br/>
                        <span>No event found</span>
                    </div>
                </div>
            </div>
    </div>
</template>

<style type="text/scss" lang="scss" scoped>
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #EEE!important;

        .container {
            margin-top: 50px;
        }

        img {
            max-width: 150px;
        }
    }
</style>

<script>
    export default {
      data () {
        return {}
      }
    }
</script>