<template>
  <div>
    <div class="mm-streams container">
      <div class="stream-day-container row" v-for="(stream_day, date) in streams">
        <div class="day-header mm-component col-md-12 col active first">
          <h2 class="day-short">{{ dayName(date) }}</h2>
          <h3 class="date-short">{{ dayFormat(date) }}</h3>
        </div>
        <Stream :class="row" v-for="(stream, index) in stream_day" :stream="stream" :key="index"></Stream>
      </div>
    </div>
  </div>
</template>

<script>
  import Moment from "moment"
  import Stream from "./Stream"
  import { mapStores } from "pinia"
  import { useVisitor } from "@/stores/visitor"
  import { useAppLanguages } from "@/stores/app-languages"

  export default {
    components: {
      Stream
    },
    props: {
      streams: Object
    },
    computed: {
      ...mapStores(useVisitor, useAppLanguages),
      inited () {
        return this.visitorStore.inited
      },
      invite () {
        return this.visitorStore.invite
      },
      language: function () {
        const lang = this.appLanguagesStore.active
        if (lang && lang.language_data) {
          return lang.language_data
        }
      }
    },
    methods: {
      dayName(date) {
        let lang = this.language.iso

        if (['en', 'nl', 'fr', 'es', 'de', 'pt', 'it'].indexOf(lang) === -1) {
          lang = 'en'
        }
        Moment.locale(lang)
        return Moment(date).format('dddd');
      },
      dayFormat(date) {
        return Moment(date).format('DD MMM');
      },
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.content.mm-html.stream-content
  padding-bottom: 0px
.mm-streams
  @include media-breakpoint-down(lg)
    width: 100%
    padding-left: 20px
    padding-right: 0px
  @include media-breakpoint-up(md)
    .row:not(:last-child)
      padding-bottom: 40px

.day-header
  padding: 25px 0px 25px 50px
  border-right: 1px solid

  &.first
    border-right: none
  &.last
    border-right: none
  h1
    line-height: 2.1rem
    font-size: 1.9rem
    font-weight: 600
    margin: 0
    margin-bottom: 12px
  h2
    font-size: 1.4rem
    font-weight: normal
    margin: 0
    margin-top: 9px
    text-transform: capitalize
  h1, h2, h3
    color: inherit
</style>
