<template>
  <div
    class="more"
    :type="type"
    :class="browser.name"
  >
    <div class="content">
      <ul id="more-menu">
        <li v-if="type === 3 && showHomepage">
          <i
            class="icon-home"
            aria-hidden="true"
          />
          <router-link
            :to="{ name: 'home' }"
            :class="{ active: 'home' === $route.name }"
            :aria-current="ariaCurrentHomePage($route.name)"
          >
            <span>Home</span>
          </router-link>
        </li>
        <li v-if="type === 3 && showLounge">
          <i
            class="icon-home"
            aria-hidden="true"
          />
          <router-link
            :to="{ name: 'lounge' }"
            :class="{ active: 'lounge' === $route.name }"
            :aria-current="ariaCurrentLounge($route.name)"
          >
            <span>Home</span>
          </router-link>
        </li>
        <template v-for="(p, index) in pages">
          <li
            v-if="p.type == 'external'"
            :key="p.page_id"
            @keydown.tab="handleTab(index)"
          >
            <i
              :class="p.icon"
              aria-hidden="true"
            />
            <a
              :href="JSON.parse(p.extra).link"
              target="_blank"
            >
              <span>{{ p.title }}</span>
            </a>
          </li>
          <li
            v-else
            :key="p.page_id"
            @keydown.tab="handleTab(index)"
          >
            <i
              :class="p.icon"
              aria-hidden="true"
            />
            <router-link
              :class="{ active: p.page_id === parseInt($route.params.pageId) }"
              :aria-current="ariaCurrentPage(p.page_id,$route.params.pageId)"
              :to="{ name: 'page', params: { pageId: p.page_id } }"
            >
              <span>{{ p.title }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
  import ariaMixin from "../../../mixins/ariaMixin";
  import { mapStores } from 'pinia'
  import { useDevice } from "@/stores/device"
  import { useAppLounge } from "@/stores/app-lounge"
  import { useAppTemplate } from "@/stores/app-template"
  import { useAppMenu } from "@/stores/app-menu"

  export default {
    mixins: [
      ariaMixin
    ],
    props: {
      pages: Array,
      type: Number
    },
    computed: {
      ...mapStores(useDevice, useAppLounge, useAppTemplate, useAppMenu),
      browser () {
        return this.deviceStore.browser || {}
      },
      showLounge () {
        return this.appLoungeStore.active
      },
      showHomepage () {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      template () {
        return this.appTemplateStore.template
      }
    },
    methods: {
      handleTab(index) {
        if (index === this.pages.length - 1) {
          this.appMenuStore.setOpen(false)
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div.more[type='2']
  padding-top: 5px

div.more[type='3']
  padding-top: 45px

div.more
  display: block
  position: absolute
  top: 100%
  z-index: 9000
  min-width: 150px

  .content
    max-height: calc(100vh - #{$topbar-height-large} - 50px)
    overflow-y: auto
    border: 1px solid

    ul#more-menu
      padding: 0
      margin: 0
      list-style-type: none

      li
        border-bottom: 1px solid
        text-align: left
        width: 100%
        white-space: nowrap
        display: flex
        align-items: center
        padding: 8px 15px
        text-transform: none

        span
          font-weight: 300

        i
          font-size: 1.56rem
          font-style: normal
          line-height: 1

      li:last-of-type
        border: none
</style>
