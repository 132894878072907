<template>
    <div id="styleguide">
        <div class="container">
            <br>
            <div class="row">
                <div class="col d-flex justify-content-between">
                    <button class="btn btn-1">{{ translate('button') }} 1</button>
                    <button class="btn btn-2">{{ translate('button') }} 2</button>
                    <button class="btn btn-3">{{ translate('button') }} 3</button>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="row">
              <div class="col text-center">
                <i :style="iconStyle" class="icon-profile"></i>
                <i :style="iconStyle" class="icon-location"></i>
                <i :style="iconStyle" class="icon-speakers"></i>
                <i :style="iconStyle" class="icon-photos"></i>
              </div>
            </div>

            <Divider :margin="30" />

            <div class="row color-6">
                <div class="col">
                    <p>'Achtergrond vlak' Lorem ipsum dolor sit amet.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Divider } from '@/components/Misc'
    import { mapStores } from 'pinia'
    import { useAppUi } from '@/stores/app-ui'

    export default {
      data () {
        return {}
      },
      created() {
        this.appUiStore.setFooter(false)
        this.appUiStore.setForceShowScrollToTop(true)
      },
      unmounted() {
        this.appUiStore.setFooter(true)
        this.appUiStore.setForceShowScrollToTop(false)
      },
      components: {
        Divider
      },
      computed: {
        ...mapStores(useAppUi),
        iconStyle () {
          return {
            fontSize: '32px',
            color: this.colors('7')
          }
        }
      }
    }
</script>

<style type="text/scss" lang="scss" scoped>
.color-1, .color-2 {
  height: 50px;
}

p {
  margin: 1rem 0;
}
</style>
