<script setup>
  import size from 'lodash/size'
  import moment from 'moment'
  import ProgramItem from './Item'
  import ProgramDay from './Day'
  import { useAppUi } from '@/stores/app-ui'
  import {computed, nextTick, onBeforeUnmount, onMounted, reactive, ref} from "vue";

  // define options
  const props = defineProps({
    widget: {
      type: Boolean,
      required: true
    },
    program: {
      type: [Object, Array],
      required: true
    },
    uniqueId: {
      type: String,
      required: true
    },
    scrollToActiveDateOnStart: {
      type: Boolean
    },
    dayTitle: {
      type: String,
      default: null
    },
    pageHasContent: {
      type: Boolean,
      default: false,
    },
    dayFormat: {
      type: String
    },
    showMonth: {
      type: Boolean
    },
    daysHeaderAbsolute: {
      type: Boolean,
      default: false
    }
  })

  // composables and stores
  const appUiStore = useAppUi()

  // reactive state
  const data = reactive({
    activeDate: null,
    dayContainerWidth: 'auto',
    dayHeaderRefHeight: null
  })

  // refs
  const days = ref({})
  const daysList = ref({})
  const programContainer = ref(null)

  const setRef = (el, name) => {
    if (el) {
      daysList.value[name] = el
    }
  }

  // computed
  const daysId = computed(() => {
    return 'days' + props.uniqueId
  })
  const programSize = computed(() => {
    return size(props.program)
  })
  const breakpoint = computed(() => {
    return appUiStore.breakingPoint
  })
  const containerClass = computed(() => {
    let containerClass = '';

    // On the homepage we apply slightly different styling
    let {pathname} = window.location
    // TODO: change isHomepage by a better name. Now this widget is used in new places.
    if(pathname === '/' || props.daysHeaderAbsolute) {
      containerClass += 'isHomepage';
    }

    // ME-1287 - Program header on mobile should not be fixed if the page has content
    if(props.pageHasContent) {
      containerClass += 'page-has-content'
    }

    return containerClass;
  })
  const dayListPaddingTop = computed(() => {
    if (appUiStore.breakingPoint.size > 4 || !data.dayHeaderRefHeight || props.pageHasContent) { // MOM-130 added props.pageHasContent to remove space on the top
      return '0px'
    }

    return `${data.dayHeaderRefHeight + 2}px !important`
  })

  // functions
  const getHeaderDateRef = (timestamp) => {
    return `${props.uniqueId}${timestamp}`
  }
  const setActiveDate = (timestamp, scroll = true) => {
    if (scroll) {
      scrollToDate(timestamp)

      data.activeDate = timestamp
    } else {
      data.activeDate = timestamp
    }
  }
  const scrollToDate = (timestamp) => {

    const headerDateRef = daysList.value[getHeaderDateRef(timestamp)]
    const heightMenuDesktop = 91
    const heightMenuMobile = 65

    if (headerDateRef && headerDateRef.offsetTop) {

      let top = 0
      if (props.widget === false && window.innerWidth < 1280) {
        top = headerDateRef.offsetTop - days.value.clientHeight - heightMenuMobile
      } else {
        top = headerDateRef.offsetTop - heightMenuDesktop
      }
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }
  const setDayContainerWidth = () => {
    data.dayContainerWidth = breakpoint.value.size <= 3 && programContainer.value.offsetWidth
      ? + (programContainer.value.offsetWidth + 3) + 'px'
      : 'auto'
  }

  // Lifecycle Hooks
  onBeforeUnmount(() => {
    window.removeEventListener('resize', setDayContainerWidth)
  })

  onMounted(() => {
    let now = moment()
    let initDate = now.unix()
    nextTick(() => { data.dayHeaderRefHeight = days.value?.clientHeight })

    if (!props.program[initDate]) {
      initDate = Object.keys(props.program)[0]
    }

    window.addEventListener('resize', setDayContainerWidth)

    setDayContainerWidth()
    if (initDate) {
      setActiveDate(initDate.toString(), props.scrollToActiveDateOnStart)
    }
  })
</script>

<template>
  <div>
    <div class="container inner program-container" ref="programContainer" :class="containerClass">
      <div class="row day-container" ref="days" :id="daysId" :style="{border: `1px solid ${colors('5')}`, width: data.dayContainerWidth}">
        <ProgramDay :uniqueId="props.uniqueId"
                     v-for="(day, timestamp, index) in props.program"
                     :key="index"
                     :class="{'last' : index === (programSize - 1), 'first': index === 0}"
                     :programSize="programSize"
                     :timestamp="timestamp"
                     :activeDate="data.activeDate"
                     :setActiveDate="setActiveDate"
                     :dayTitle="props.dayTitle"
                     :dayFormat="props.dayFormat"
                     :showMonth="props.showMonth">
        </ProgramDay>
      </div>
      <div class="day-list" ref="dayList" :style="{ 'padding-top': dayListPaddingTop }">
        <div v-for="(day, timestamp, index) in props.program" :key="index" role="list">
          <div class="row" :class="{'mm-program-singleday' : programSize === 1}">
            <div class="col-12 date-header text-center" :ref="el => setRef(el, getHeaderDateRef(timestamp))" :id="getHeaderDateRef(timestamp)" data-cy="day-row">
              {{ day.date }}
            </div>
          </div>
          <ProgramItem v-for="(item, i) in day.items" :item="item" :key="i"></ProgramItem>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.container
  max-width: map-get($container-max-widths, "xl")

.day-container
  min-height: 135px
.mm-program-singleday
  opacity: 0
  height: 0
  overflow: hidden
.day-container
  flex-wrap: nowrap
  overflow-x: hidden
  z-index: 1
  position: -webkit-sticky
  position: sticky
  width: auto
@include media-breakpoint-down(lg)
  .program-container
    padding-left: 18px
    padding-right: 18px
    width: 100%
  .day-container
    position: fixed
    width: 100%
    overflow-x: auto !important
  .isHomepage
    .day-container
      position: absolute !important
  .page-has-content
    .day-container
      position: initial !important
  .page-has-content
    .day-list
      padding-top: 0

.date-header
  border-right: 1px solid
  border-left: 1px solid
  font-size: 0.89rem
  padding: 5px 0px
  font-weight: 600

@include media-breakpoint-down(md)
  .item:last-of-type
    border-bottom: none
</style>
