<template>
  <div class="stream-embed" ref="streamEmbed" >
    <div :style="sizeStreamEmbed">
      <div class="stream-embed-other" ref="stream-embed-other" v-html="stream_data.embed"></div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass">
.stream-embed
  margin: 0 auto

  .stream-embed-other
    position: relative
    margin: 0 auto
    width: 100%
    height: 100%
    padding: 100px
    @media (max-height: 1024px)
      padding: 20px
    @include media-breakpoint-down(lg)
      padding: 0px

    iframe
      max-height: 100%
      max-width: 100%
      height: 100vh
      width: 100vw
      @include media-breakpoint-down(lg)
        height: 100%
        width: 100%
</style>
<script>
  import loadScriptsMixin from "../../../mixins/loadScriptsMixin";
  import { mapStores } from "pinia"
  import { useAppUi } from "@/stores/app-ui"

  export default {
    props: {
      stream_data: Object
    },
    data() {
      return {
        streamHeight: 480,
        streamWidth: 640
      }
    },
    mixins: [
      loadScriptsMixin
    ],
    mounted () {
      this.setDimensions()
      let scripts = Array.from(this.$refs['stream-embed-other'].querySelectorAll('script'))
      this.loadScripts(scripts)
    },
    methods: {
      setDimensions() {
        this.streamHeight = this.$refs.streamEmbed.clientHeight;
        this.streamWidth = this.$refs.streamEmbed.clientWidth;
      }
    },
    computed: {
      ...mapStores(useAppUi),
      device() {
        const breakPoint = this.appUiStore.breakingPoint
        return breakPoint.size <= 3 ? 'mobile' : 'desktop';
      },
      sizeStreamEmbed() {
        return {
          height: `${this.streamHeight}px`,
          width: `${this.streamWidth}px`
        }
      }
    },
    watch: {
      '$route.path' () {
        this.initVideo()
      }
    }
  }
</script>

