<template>
    <select id="app-language-switch" class="form-select" v-model="activeLanguage" v-if="languages.length > 1">
      <option :value="language.language_id" v-for="(language, index) in languages" :key="index">
        {{ language.language_data.iso }}
      </option>
    </select>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppMenu } from '@/stores/app-menu'
  import { useAppTranslations } from '@/stores/app-translations'

  export default{
    computed: {
      ...mapStores(useAppLanguages, useAppMenu, useAppTranslations),
      languages: function () {
        return this.appLanguagesStore.all
      },
      activeLanguage: {
        get() {
          return this.appLanguagesStore.active.language_id
        },
        set(value) {
          this.appLanguagesStore.setActiveLanguage(value).then(() => {
            this.appMenuStore.setOpen(false)
            this.appTranslationsStore.load().then(() => {
              this.$router.push('/')
            })
          })
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
select#app-language-switch, select#app-language-switch:focus
  position: fixed
  top: 10px
  right: 10px
  width: auto
  z-index: 9000
  border: 2px solid
  background-color: transparent
  text-transform: uppercase

  option
    color: #000
    text-transform: uppercase
</style>
