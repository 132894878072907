<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";

const appSurveyStore = useAppSurvey()

const { answerState } = storeToRefs(appSurveyStore)

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  }
})

const answerSelected = ref([])

onMounted(() => {
  const question = { ...props.question }

  answerState.value = question.answer ?? { selection: [] }
  answerSelected.value = question.answer ? question.answer.selection : []
})

const maxAnswers = computed(() => {
  if (props.question.value) {
    return parseInt(props.question.value)
  }

  return null
})

const disableOption = (optionId) => {
  if (!maxAnswers.value || !answerState.value) {
    return false
  }

  return !answerState.value.selection.includes(optionId)
    && answerState.value.selection.length === maxAnswers.value
}

watch(() => answerSelected.value, (val) => {
  answerState.value = {
    other: "",
    selection: val
  }
})
</script>

<template>
  <div class="mm-custom-form">
    <div
      v-for="(option, index) in props.question.options"
      :key="index"
      class="form-check"
    >
      <input
        :id="'checkbox' + option.option_id"
        v-model="answerSelected"
        class="form-check-input"
        type="checkbox"
        :value="option.option_id"
        :disabled="disableOption(option.option_id)"
        data-cy="survey-checkbox"
        aria-describedby="survey-question-legend"
      >
      <label
        :for="'checkbox' + option.option_id"
        class="form-check-label"
      >
        <p>{{ option.option }}</p>
      </label>
    </div>
  </div>
</template>

<style lang="scss" type="text/scss" scoped>
input[type="checkbox"] {
  transform: scale(2);
}
</style>
