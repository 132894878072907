<script setup>
import { computed, inject } from 'vue'
import { rowify } from 'lib'
import ResourceCard from './ResourceCard.vue'

const service = inject('service')

const props = defineProps({
  resource: {
    type: String,
    default: () => ''
  },
  resourceElements: {
    type: Array,
    default: () => []
  }
})

const rows = computed(() => {
  return rowify(props.resourceElements, service.value)
})

const isItCustomContent = (element) => {
  return element.content !== ''
}

const isItResource = (element) => {
  return element[service.value]
}
</script>

<template>
  <div :class="`mm-resource mm-${props.resource}`">
    <div 
      v-for="(chunk, chunkKey) in rows"
      :key="chunkKey"
      class="row justify-content-md-center" 
    >
      <div 
        v-for="(chunkElement, key) in chunk"
        :key="key"
        class="col-12 resource"
        :class="{'col-lg-3 col-md-3': isItResource(chunkElement)}"
      >
        <div 
          v-if="!isItResource(chunkElement) && isItCustomContent(chunkElement)"
          class="content mm-html contact-content"
          v-html="chunkElement.content"
        />
        <div 
          v-else
          class="row g-0"
        >
          <ResourceCard
            :resource-element="chunkElement"
          />
        </div>

        <hr
          v-if="isItResource(chunkElement)"
          class="col-12 d-md-none"
        >
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.content.mm-html.resource-content
  padding-bottom: 0px
.mm-resource
  @include media-breakpoint-up(md)
    .row:not(:last-child)
      padding-bottom: 40px

.resource
  display: inline-block
</style>