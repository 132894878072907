<template>
    <div id="language-switcher" v-if="languages.length > 1">
        <div
          v-for="(language, index) in languages"
          :key="index"
          @keypress.enter="setLanguage(language.language_id)"
          @click="setLanguage(language.language_id)"
          role="link"
          tabindex="0"
          :class="{'mm-is-active': language.language_id === activeLanguage }"
        >
            <i class="icon-language-selected" v-if="language.language_id === activeLanguage"></i>
            <i class="icon-language-unselected" v-if="language.language_id !== activeLanguage"></i>
            <span>
                {{ language.language_data.name }}
            </span>
        </div>
    </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppMenu } from '@/stores/app-menu'
  import { useAppTranslations } from '@/stores/app-translations'

  export default {
    props: {
    },
    data () {
      return {}
    },
    computed: {
      ...mapStores(useAppLanguages, useAppMenu, useAppTranslations),
      languages() {
        return this.appLanguagesStore.all
      },
      activeLanguage: {
        get () {
          return this.appLanguagesStore.active.language_id
        },
        set (value) {
          this.appLanguagesStore.setActiveLanguage(value).then(() => {
            let lang = this.languages.filter(lang => lang.language_id === this.activeLanguage)[0]
            document.documentElement.setAttribute('lang', lang.language_data.iso) // set lang property to html
            this.appMenuStore.setOpen(false)
            this.appTranslationsStore.load().then(() => {
              this.$router.push('/')
            })
          })
        }
      }
    },
    methods: {
      setLanguage (language) {
        this.activeLanguage = language
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div#language-switcher
  border-top: 1px solid
  list-style-type: none
  font-size: 1.0rem
  margin: 0
  padding: 0
  padding-top: 20px
  font-weight: 300
  width: 100%
  .mm-is-active > span
    text-decoration: underline
  div
    font-size: 1rem
    display: flex
    align-items: flex-start
    line-height: 26px
    padding-bottom: 25px
    cursor: pointer

    i
      font-size: 1.78rem
      display: inline-block
      font-style: normal
      margin-right: 15px

    span
      display: inline-block
      align-self: center
</style>
