<template>
  <div v-if="qa">
    <h3 class="qa-header">{{ translate('qa_title') }}</h3>
    <div class="qa-form">
      <textarea name="qa-textarea" class="qa-textarea" v-model="question" :placeholder="translate('qa_type_here')"></textarea>
      <div 
        v-if="qa.anonymous" 
        v-html="translate('interaction_qa_is_anonymous')"
        class="px-4 py-2 text-center anonymous-description"
      ></div>
      <div class="qa-send-btn">
        <button class="btn btn-1" v-on:click="sendQuestion()">{{ translate('qa_send') }}</button>
      </div>
    </div>
    <div class="qa-result-sending" v-if="question_sent">
      <div class="qa-result-message btn-1">
        <span v-if="success_sending" v-html="translate('qa_thank_you')" class="qa-message"></span>
        <span v-else v-html="this.error_message" class="qa-message"></span>
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" >
.qa-header
  margin-bottom: 20px
  margin-left: 25px
  margin-right: 25px
  padding-top: 20px
  color: #000000
  font-weight: bold

.qa-form
  height: 260px
  padding: 0px 24px 60px 24px

.qa-textarea
  border: none
  height: 100%
  width: 100%
  background-color: #ffffff
  border: solid 0.5px #e3e3e3
  padding: 15px

.qa-send-btn
  text-align: center
  display: flex
  .btn
    width: 100%
    height: 40px

.qa-result-sending
  position: absolute
  right: 0
  left: 0
  bottom: 50px
  height: 150px
  display: -webkit-box
  background-color: white

  .qa-result-message
    display: flex
    align-items: center
    text-align: center
    width: 100%
    opacity: 0.7

    .qa-message
      width: 100%
      font-size: 120%
      color: inherit !important

      span,p
        font-size: 20px
        font-weight: 600
        color: inherit
        margin-bottom: 0px

@include media-breakpoint-down(lg)
  .qa-header
    margin-left: 2px
    padding-top: 0px

  .qa-form
    height: 260px
    padding: 30px 24px 60px 24px
    border: solid 0.5px #e3e3e3
    background-color: #f9f9f9

    .qa-textarea
      border: none
      padding: 0
      overflow: scroll
      background-color: #f9f9f9

  .qa-result-sending
    position: fixed
    bottom: 0

  .anonymous-description
    margin-top: 60px
</style>

<script>
  import { mapStores } from 'pinia'
  import { useAppLiveModule } from '@/stores/app-liveModule'

  export default {
    props: {
      qa: Object
    },
    data () {
      return {
        question: '',
        question_sent: false,
        success_sending: Boolean,
        error_message: ''
      }
    },
    methods: {
      sendQuestion() {
        let hash = this.qa.hash;
        let question = this.question;

        this.question_sent = true;

        if(question.length > 2) {
          this.appLiveModuleStore.sendQuestion({hash, question}).then((result) => {
            if(result.sent) {
              this.success_sending = true;
              this.question = '';
            } else {
              this.success_sending = false;
              this.error_message = result.message;
            }
          });
        } else {
          this.success_sending = false;
          this.error_message = this.translate('qa_question_empty');
        }
        setTimeout(() => this.question_sent = false, 3000)
      }
    },
    computed: {
      ...mapStores(useAppLiveModule)
    }
  }
</script>
