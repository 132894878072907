<template>
  <div id="lounge-footer-wrapper" style="background-color: #0A246A">
    <div v-for="(widget, index) in widgets_footer" :key="index">
      <component :is="widget.type + 'Component'" :widget="widget" :program="program" />
    </div>
  </div>
</template>

<script>

import Tickertape from "../../pages/loungeWidgets/tickertape/tickertape-v1";
import Nownext from "../../pages/loungeWidgets/nownext/nownext-v1";

export default {
  props: {
    widgets_footer: Array,
    program: Object|Array
  },
  computed: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    'tickertapeComponent': Tickertape,
    'nownextComponent': Nownext
  }
};


</script>

<style lang="css" type="text/css" scoped>

#lounge-footer-wrapper {
  color: white;
  max-height: 112px;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

</style>
