<template>
  <div>
    <label id="prevPage" @click="previousPage()" role="link" tabindex="0" @keypress.enter="previousPage()" data-cy="new-go-timeline"><i class="icon-arrow-left align-middle return-link"></i> Timeline</label>
    <div class="justify-content-center">
      <div class="news-card regular-post">
        <news-header :data="post" :title="post.title"></news-header>
        <div class="news-card image" :style="{ backgroundImage: 'url('+cardCover()+')' }"></div>
        <div class="news-card body">
          <div v-html="post.content"></div>
          <p class="news-card ext-url" v-if="post.type == 'regular' && post.external_url != ''" v-on:click.stop="goto(post.external_url)">{{ translate('more_information') }}</p>
        </div>
        <news-footer :data="post" :isOverview="false"></news-footer>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
#prevPage
  cursor: pointer

.regular-post
  width: 100%
  margin: 20px 0px
  display: flex
  position: relative
  border-radius: 6px
  flex-direction: column
  border: 1px solid rgba(0, 0, 0, 0.12)

.body
  padding: 16px

.image
  height: 160px
  display: flex
  padding: 20px 12px
  background: #484b4d
  min-height: 160px
  flex-direction: column
  background-size: cover
  background-repeat: no-repeat
  background-position: center

.ext-url
  text-decoration: underline
  font-style: italic
  cursor: pointer
  margin-top: 5px

#prevPage
  @media (max-width: 1279px)
    display: none

</style>
<script>
  import Header from "../NewsHeader.vue"
  import Footer from "../NewsFooter.vue"

  export default {
    props: {
      post: Object
    },
    methods: {
      previousPage () {
        this.$router.go(-1)
      },
      goto(url) {
        window.open(url, '_blank');
      },
      cardCover () {
        // Check if news post has an image
        if (this.post.image && this.post.image != '') {
          return this.post.image
        }
        // Fallback
        return "https://tool.momice.com/include/momice/defaults/images/news_page_type_"+this.post.type+".png";
      }
    },
    components: {
      'news-header': Header,
      'news-footer': Footer
    }
  }
</script>
