<template>
    <div class="row attachments justify-content-center">
        <div class="col-lg-8">
            <div class="row attachment justify-content-center" v-for="(a, index) in attachments">
                <div class="col-12 order-2 col-sm-9 text-sm-start text-center attachment-text" v-if="a.attachment">
                    <h2 class="mm-color-text">{{ a.title }}</h2>
                    <div class="mm-color-text" v-html="a.content" v-if="a.content"></div>
                    <div v-if="!a.content"><p></p></div>
                    <a
                      :href="a.link"
                      class="btn btn-2"
                      target="_blank"
                      :aria-label="ariaLabelDownloadAttachment(a)"
                    >
                      {{ a.download_button || translate('download') }}
                    </a>
                </div>
                <div v-if="!a.attachment" class="col-12">
                  <div class="row content mm-html attachment-content" v-html="a.content"></div>
                </div>
                <div class="col-6 order-1 col-sm-3 attachment-type-container" v-if="a.attachment">
                  <div class="attachment-type">
                    <div class="content align-self-center">
                      <div class="table">
                        <div class="table-cell">
                          <p class="h1 extension">{{ a.extension }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 order-3" v-if="index !== (attachments.length-1)"><hr/></div>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.attachments
  .attachment-content
    padding-top: 27px

  .attachment
    hr
      margin: 30px 0

    .attachment-type-container
      left: auto

    @include media-breakpoint-down(lg)
      .attachment-type
        margin-bottom: 30px

    @include media-breakpoint-down(sm)
      .attachment-type-container
        left: 25%

    .attachment-type
      width: 100%
      padding-bottom: 100%
      position: relative

      .content
        position: absolute
        height: 100% /* = 100% - 2*10% padding */
        width: 100% /* = 100% - 2*5% padding */
        padding: 10% 5%
        text-align: center

        .table
          display: table
          height: 100%
          width: 100%

          .table-cell
            display: table-cell
            vertical-align: middle
            height: 100%
            width: 100%

            .extension
              display: inline

    .attachment-text
      h2
        display: block
        font-weight: 600
        padding-bottom: 0
        margin-bottom: 0px
        margin-top: -10px
        text-align: left
      @include media-breakpoint-only(xs)
        h2
          text-align: center
      // @include media-breakpoint-only(sm)
      //   h2
      //     text-align: left
      p
        a
          font-weight: 600
</style>
<script>
  export default {
    props: {
      attachments: Array
    },
    methods: {
      ariaLabelDownloadAttachment(attachment) {
        return `${this.translate('download')} ${attachment.title}`
      }
    }
  }
</script>
