<template>
    <div v-if="languages.length > 1" id="language-dropdown">
        <select id="language-switch" class="form-select btn" v-model="activeLanguage" role="link" tabindex="0">
            <option v-for="(language, index) in languages" :key="index" :value="language.language_id">
                {{ language.language_data.iso }}
            </option>
        </select>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAppLanguages } from '@/stores/app-languages'
    import { useAppMenu } from '@/stores/app-menu'
    import { useAppTranslations } from '@/stores/app-translations'

    export default {
      data () {
        return {}
      },
      computed: {
        ...mapStores(useAppLanguages, useAppMenu, useAppTranslations),
        languages () {
          return this.appLanguagesStore.all
        },
        activeLanguage: {
          get () {
            return this.appLanguagesStore.active.language_id
          },
          set (value) {
            this.appLanguagesStore.setActiveLanguage(value).then(() => {
              let lang = this.languages.filter(lang => lang.language_id === this.activeLanguage)[0]
              document.documentElement.setAttribute('lang', lang.language_data.iso) // set lang property to html
              this.appMenuStore.setOpen(false)
              this.appTranslationsStore.load().then(() => {
                this.$router.push('/')
              })
            })
          }
        }
      }
    }
</script>

<style type="text/sass" lang="sass" scoped>
select#language-switch, select#language-switch:focus
  margin: 0 20px
  width: auto
  background-color: transparent
  border: 2px solid
</style>
