<template>
  <div class="page-flex mm-page mm-news-detail" v-if="ready">
    <div class="container">
      <component v-if="newsItem" :is="newsItem.type + 'Component'" :post="newsItem" ></component>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.mm-news-detail
  .container
    max-width: 700px

</style>
<script>

  import Regular from './Types/Regular.vue'
  import Podcast from './Types/Podcast.vue'
  import Video from './Types/Video.vue'
  import Poll from './Types/Poll.vue'
  import { mapStores } from 'pinia'
  import { useAppNews } from '@/stores/app-news'
  import { useApp } from '@/stores/app'

  export default {
    data () {
     return {
      ready: false
     }
    },
    computed: {
      ...mapStores(useAppNews, useApp),
    	newsItem() {
        return this.appNewsStore.getCurrentNewsItem
    	}
    },
    created() {
    	new Promise((resolve) => {
          this.appNewsStore.setCurrentNewsItem(this.$route).then((result) => {
            this.ready = true;

          }, (err) => {
            window.handle_error(err, 'page init')
            this.ready = true;
          }).catch(() => {
            this.ready = true;
            this.appStore.set404({ route: this.$route })
          })
        })
    },
    methods: {
    },
    components: {
      'regularComponent': Regular,
      'podcastComponent': Podcast,
      'videoComponent': Video,
      'pollComponent': Poll
    }
  }
</script>
