<script setup>
  // imports
  import Moment from "moment";
  import { AdvancedButton } from '@/components/Misc';
  import {env} from "../../env";
  import { useAppLanguages } from '@/stores/app-languages'
  import { useVisitor } from '@/stores/visitor'
  import { useAppUi } from '@/stores/app-ui'
  import { useAppPages } from '@/stores/app-pages'
  import {computed, onMounted, reactive} from "vue";
  import { useTranslations } from '@/composables/translations'
  import {useRouter} from "vue-router";


  // define options
  const props = defineProps({
    item: {
      type: Object,
      required: true
    },
  })

  // composables and stores
  const { translate } = useTranslations()
  const appLanguagesStore = useAppLanguages()
  const visitorStore = useVisitor()
  const appUiStore = useAppUi()
  const appPagesStore = useAppPages()
  const router = useRouter()

  // reactive state
  const data = reactive({
    visible: false,
    status: null,
    realTime: Moment(),
    backend: null
  })

  // computed
  const breakpoint = computed(() => {
    return appUiStore.breakingPoint
  })

  const liveModuleActive = computed(() => {
    return (props.item.stream !== null || props.item.voting !== null || props.item.qa !== null || props.item.chat !== null) && !livestreamPage.value;
  })
  const buttonCta = computed(() => {
    return translate('choose_stream')
  })
  const indicator  = computed(() => {
    // Define indicator class
    return 'item-indicator item-indicator-' + data.status
  })
  const itemAccessIcon = computed(() => {
    if(publicAccess.value) {
      return 'item-access-public'
    } else {
      return 'item-access-private'
    }
  })
  const statusTranslation = computed(() => {
    // Get translation for stream status
    return translate('live_stream_status_label_' + data.status)
  })
  const publicStatus = computed(() => {
    if(publicAccess.value) {
      return translate('stream_access_public')
    } else {
      return translate('stream_access_private')
    }
  })
  const publicAccess = computed(() => {
    return props.item.stream?.public === 'yes' || props.item.voting?.public || props.item.qa?.public || props.item.chat?.public;
  })
  const livestreamPage = computed(() => {
    const lang = appLanguagesStore.activeLanguage
    return appPagesStore.all.find(p => p.type === 'live' && p.language_id === lang)
  })
  const inited = computed(() => {
    return visitorStore.inited
  })
  const invite = computed(() => {
    return visitorStore.invite
  })
  const streamLink = computed(() => {
    let link = data.backend + props.item.stream.stream_url;
    if(inited.value) {
      link = link + '/' + invite.value.hash;
    }
    return link;
  })
  const informationCollapsed = computed(() => {
    return data.visible ? 'true' : 'false';
  })
  const hasChoosenWorkshop = computed(() => {
    return props.item.workshop_field && props.item.workshop_field.choosen_workshop
  })

  // Functions
  const toggleVisibility  = () => {
    data.visible = !data.visible
  }
  // Does the visitor have permissions to join "Momice online" (livestream, chat, voting or QA)
  const hasPermissionToJoin = () => {
    return props.item.access_allowed
  }
  const hasStream = () => {
    return props.item.stream !== null
  }
  const isTimeToJoin = () => {
    let walkInDate = Moment(props.item.datetime_start);
    if(hasStream()) {
      walkInDate = Moment(props.item.stream.walk_in_date);
    }

    // Check if walkin time is reached
    if(walkInDate < data.realTime) {
      // Check if end date is reached
      if(isFinished()) {
        data.status = 'finished';
        return false
      } else {
        // End date is not reached
        return true
      }
    } else {
      // Not yet started
      return false
    }
  }
  const isFinished = () => {
    let end_date = Moment(props.item.end_date);
    if(hasStream()) {
      end_date = Moment(props.item.stream.end_date);
    }

    // Check if end date is reached
    return end_date < data.realTime;
  }
  // Can someone join based on permissions and time
  const canJoin = () => {
    if(hasPermissionToJoin()) {
      // Validate times
      if(isTimeToJoin()) {
        data.status = 'join';
        return true;
      } else {
        return false;
      }
    } else {
      // No permissions
      return false;
    }
  }
  // Join stream
  const join = () => {
    if (canJoin()) {
      // Check if stream has access and external URL
      if (hasStream() && props.item.stream.platform === 'url') {
        window.open(streamLink.value, '_blank');
      } else {
        // Open live module
        router.push({name: 'live-module', params: {pageId: props.item.page_id}})
      }
    }
  }

  // Lifecycle Hooks
  onMounted(() => {
    // Set backend
    data.backend = env.API_URL;
    // interval for realTime variable
    setInterval(() => data.realTime = Moment(), 5000);

    // Determine call to action label
    if (canJoin()) {
      // Visitor can join based on time and permissions
      data.status = 'join';
    } else {
      if (hasPermissionToJoin()) {
        if (isFinished()) {
          // Stream is finished
          data.status = 'finished';
        } else {
          // Stream not started yet
          data.status = 'not-started';
        }
      } else {
        if (isFinished()) {
          // Stream is finished
          data.status = 'finished';
        } else {
          data.status = 'no-permission';
        }
      }
    }
  });
</script>
<template>
  <div>
    <div
      v-if="liveModuleActive"
      class="row item item-live-module"
      role="listitem"
      data-cy="program-item"
    >
      <div
        class="col-3 col-md-2 item-time"
        :class="{ highlight: (props.item.highlight)}"
      >
        <p class="mrb-0 time-block">
          <strong>{{ props.item.time_start }}</strong>
        </p>
        <p class="fw-300 time-block">
          {{ props.item.time_end }}
        </p>
      </div>

      <div class="col-9 col-md-7 item-content">
        <p class="title mrb-0 h3">
          {{ props.item.title }}
        </p>
        <p
          v-if="props.item.location"
          class="location h3"
        >
          {{ props.item.location }}
        </p>
        <div v-if="hasChoosenWorkshop" data-test="workshop-chosen-block">
          <p class="title mrb-0 h3" data-test="workshop-question">
            {{ props.item.workshop_field.description }}
          </p>
          <p data-test="workshop-answer">
            {{ props.item.workshop_field.choosen_workshop.name }}
          </p>
        </div>
        <p
          v-if="props.item.image || props.item.content"
          class="read-more h3"
          role="button"
          tabindex="0"
          :aria-label="data.visible ? translate('show_less_about', { text: props.item.title }) : translate('read_more_about', { text: props.item.title })"
          :aria-expanded="informationCollapsed"
          @click="toggleVisibility()"
          @keypress.enter="toggleVisibility()"
        >
          <span v-if="!data.visible">{{ translate('more_information') }}</span>
          <span v-if="data.visible">{{ translate('less_information') }}</span>
        </p>
        <div
          v-if="data.visible && breakpoint.size >= 3"
          class="row read-more-info"
        >
          <div
            v-if="props.item.content"
            :class="{ 'col-md-6': props.item.image, 'col-12': !props.item.image }"
            class="content col-12 mm-html"
            v-html="props.item.content"
          />
          <div
            v-if="props.item.image"
            :class="{ 'col-md-6': props.item.content, 'col-12': !props.item.content }"
            class="col-12 read-more-img-desktop"
          >
            <img
              :src="props.item.image"
              class="img-fluid"
              role="presentation"
              alt=""
            >
          </div>
        </div>
        <div class="d-md-none col-12 item-live item-status-col">
          <div class="item-status row">
            <div class="item-status-indicator-col col-6">
              <div
                :title="data.status"
                :class="indicator"
              >
                <span>{{ statusTranslation }}</span>
              </div>
            </div>
            <div class="item-access col-6">
              <div
                :class="itemAccessIcon"
                class="item-access-icon"
              />
              <span>{{ publicStatus }}</span>
            </div>
          </div>
          <AdvancedButton
            v-if="canJoin()"
            :loading="false"
            class="mm-btn-stream btn btn-1"
            data-cy="go-live-module"
            @clickEvent="join()"
          >
            <template #loading>
              <span>{{ 'loading' }}</span>
            </template>
            <template #default>
              <span>{{ buttonCta }}</span>
            </template>
          </AdvancedButton>
        </div>
      </div>

      <div class="d-none d-md-block col-md-3 offset-md-0 offset-4 col-8 item-live item-status-col">
        <div class="item-status row">
          <div class="item-status-indicator-col col-12 col-lg-6">
            <div
              :title="data.status"
              :class="indicator"
            >
              <span>{{ statusTranslation }}</span>
            </div>
          </div>
          <div class="item-access col-12 col-lg-6">
            <div
              :class="itemAccessIcon"
              class="item-access-icon"
            />
            <span>{{ publicStatus }}</span>
          </div>
        </div>
        <AdvancedButton
          v-if="canJoin()"
          :loading="false"
          class="mm-btn-stream btn btn-1"
          @clickEvent="join()"
        >
          <template #loading>
            <span>{{ 'loading' }}</span>
          </template>
          <template #default>
            <span>{{ buttonCta }}</span>
          </template>
        </AdvancedButton>
      </div>
    </div>

    <div
      v-else
      class="row item"
      role="listitem"
    >
      <div
        class="col-3 col-md-2 item-time"
        :class="{ highlight: (props.item.highlight)}"
      >
        <p class="mrb-0 time-block">
          <strong>{{ props.item.time_start }}</strong>
        </p>
        <p class="fw-300 time-block">
          {{ props.item.time_end }}
        </p>
      </div>

      <div class="col-9 col-md-10 item-content">
        <p class="title mrb-0 h3">
          {{ props.item.title }}
        </p>
        <p
          v-if="props.item.location"
          class="location h3"
        >
          {{ props.item.location }}
        </p>
        <div v-if="hasChoosenWorkshop" data-test="workshop-chosen-block">
          <p class="title mrb-0 h3" data-test="workshop-question">
            {{ props.item.workshop_field.description }}
          </p>
          <p data-test="workshop-answer">
            {{ props.item.workshop_field.choosen_workshop.name }}
          </p>
        </div>
        <p
          v-if="props.item.image || props.item.content"
          class="read-more h3"
          role="button"
          tabindex="0"
          :aria-label="data.visible ? translate('show_less_about', { text: props.item.title }) : translate('read_more_about', { text: props.item.title })"
          :aria-expanded="informationCollapsed"
          @click="toggleVisibility()"
          @keypress.enter="toggleVisibility()"
        >
          <span v-if="!data.visible">{{ translate('more_information') }}</span>
          <span v-if="data.visible">{{ translate('less_information') }}</span>
        </p>
        <div
          v-if="data.visible && breakpoint.size >= 3"
          class="row read-more-info"
        >
          <div
            v-if="props.item.content"
            :class="{ 'col-md-6': props.item.image, 'col-12': !props.item.image }"
            class="content col-12 mm-html"
            v-html="props.item.content"
          />
          <div
            v-if="props.item.image"
            :class="{ 'col-md-6': props.item.content, 'col-12': !props.item.content }"
            class="col-12 read-more-img-desktop"
          >
            <img
              :src="props.item.image"
              class="img-fluid"
              role="presentation"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 30px bottom -->
    <div
      v-if="data.visible && breakpoint.size < 3"
      class="row read-more-info item"
    >
      <div
        v-if="props.item.image"
        class="col-12 read-more-img"
      >
        <img
          :src="props.item.image"
          class="img-fluid"
          role="presentation"
          alt=""
        >
      </div>
      <div
        v-if="props.item.content"
        :class="{ 'col-md-6': props.item.image, 'col-12': !props.item.image }"
        class="content col-12 mm-html"
        v-html="props.item.content"
      />
    </div>
  </div>
</template>

<style lang="scss" type="text/scss">
.read-more-info {
  padding-top: 30px !important;
}
.read-more-img {
  margin-top: -40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 30px;
}
.read-more-img-desktop {
  top: -5px;
}
.item {
  border-right: 1px solid;
  border-left: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid;

  .item-time {
    border-right: 4px solid;
    padding-top: 22px;
    padding: 10px;
  }
  .item-content {
    padding: 35px;
    padding-top: 22px;
    padding-bottom: 25px;
    padding-top: 10px;

    .h3 {
      text-align: left;
    }

    .h3.title {
      font-weight: 600;
    }

    .h3.read-more {
      margin-bottom: 0;
      font-weight: 600;
      cursor: pointer;
    }
  }



  .item-status-col {
    padding-left: 0px;
    padding-right: 0px;

    .item-status {
      margin-bottom: 20px;
      margin-top: 8px;

      .item-indicator {
        height: 15px;
        width: 15px;
        border: 0px;
        border-radius: 50%;
      }

      span {
        font-size: 80%;
        margin-left: 20px;
        position: absolute;
        margin-top: -2px;
      }

      .item-indicator-join {
        background-color: green;
      }

      .item-indicator-not-started {
        background-color: yellow;
      }

      .item-indicator-finished {
        background-color: orange;
      }

      .item-indicator-no-permission {
        background-color: red;
      }

      .item-access {
        .item-access-icon {
          width: 20px;
          height: 20px;
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
          float: left;
          margin-top: -2px;
        }

        span {
          font-size: 80%;
          margin-left: 5px;
          position: absolute;
          line-height: 91%;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
