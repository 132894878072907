<template>
  <button @click="$emit('clickEvent')" :disabled="disabled">
    <div v-if="loading">
      <slot name="loading">Loading</slot><span class="dots" v-if="showDots">{{ loadingDots }}</span>
    </div>
    <div v-else>
      <slot name="default">Submit</slot>
    </div>
  </button>
</template>

<script>
  export default {
    name: 'LoadingButton',
    data () {
      return {
        loadingDots: '.'
      }
    },
    mounted () {
      setInterval(() => {
        if (this.loadingDots.length >= 3) {
          this.loadingDots = '.'
        } else {
          this.loadingDots += '.'
        }
      }, 500)
    },
    props: {
      disabled: {
        default: false,
        type: Boolean,
        required: false
      },
      loading: {
        default: false,
        type: Boolean,
        required: false
      },
      showDots: {
        default: true,
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.dots
  display: inline-block
  width: 20px
  text-align: left
</style>
