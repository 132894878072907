<script setup>
import { reactive } from 'vue'

const emit = defineEmits(['uploadPhoto', 'doneUpload'])

const props = defineProps({
  multiple: {
    type: Boolean,
    default: true
  }
})

const data = reactive({
  uploading: false,
  accept: 'image/*',
  maxFileSize: 8388608,
  fileLimit: 25
})

const handleUploadPhoto = (files) => {
  data.uploading = true
  files.forEach(photo => {
    emit('uploadPhoto', photo)
  })

  handleDoneUpload()
}

const handleDoneUpload = () => {
  data.uploading = false
  emit('doneUpload')
}

</script>

<template>
  <div class="photo-uploader">
    <FileUpload
      :multiple="props.multiple"
      :accept="data.accept"
      :maxFileSize="data.maxFileSize"
      :fileLimit="data.fileLimit"
      :pt="{
        root: 'file-upload-dropzone',
        input: 'd-none'
      }"
    >
      <template #header="{ chooseCallback, clearCallback, files }">
        <div class='w-100 p-3' :style="{ background: colors('6') }">
          <div 
            @click="chooseCallback()"
            class="btn btn-2 btn-upload-manual me-2"
            :class="{ disabled: data.uploading }"
            data-cy="photos-select"
          >
            {{ translate('photo_select') }}
          </div>
          <div 
            @click="handleUploadPhoto(files)"
            class="btn btn-3 me-2"
            :class="{ disabled: !files || files.length === 0 || data.uploading }"
            data-cy="photos-upload"
          >
            {{ translate('upload') }}
          </div>
          <div 
            @click="clearCallback()"
            class="btn btn-3 me-2"
            :class="{ disabled: !files || files.length === 0 || data.uploading }"
            data-cy="photos-cancel"
          >
            {{ translate('cancel') }}
          </div>
          <span>
            {{ translate('max_upload_size') }} 8MB
          </span>
        </div>
      </template>
      <template #empty>
        <div class='w-100 p-3'>
          <p>{{ translate('drag_and_drop_files') }}</p>
        </div>
      </template>
      <template #content="{ files }">
        <div v-for="(file, index) of files" :key="file.name + file.type + file.size + index" class="card me-3">
          <div class="p-3">
            <img role="presentation" :alt="file.name" :src="file.objectURL" width="150" />
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

