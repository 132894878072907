<script setup>
import { onMounted } from 'vue'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";

const appSurveyStore = useAppSurvey()

const { answerState } = storeToRefs(appSurveyStore)

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  },
  validation: Boolean
})

onMounted(() => {
  const question = { ...props.question }

  answerState.value = question.answer ?? null
})

</script>

<template>
  <div>
    <TextArea 
      id="survey-question"
      v-model="answerState"
      class="form-control"
      :class="{ error: !props.validation}"
    />
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
textarea
  border: 1px solid
  min-height: 125px !important
</style>
