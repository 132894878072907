<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
              <template v-if="version == undefined">
                <div class="col-12 col-md-6 col-lg-4 podcast" v-for="(podcast, i) in podcasts" :key="i">
                  <div class="podcast-image-container">
                    <router-link :to="goTo(podcast)" :aria-label="goToDescription('podcast', podcast.title)">
                      <img :src="cardCover(podcast)" class="podcast-image" :alt="podcast.title">
                    </router-link>
                  </div>
                  <div class="text-center pt-3">
                    <router-link
                      :to="goTo(podcast)"
                      :aria-label="goToDescription('podcast', podcast.title)"
                      class="mm-color-text text-center h3 podcast-title"
                    >
                      {{ podcast.title }}
                    </router-link>
                  </div>
                </div>
              </template>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.podcast
  margin-bottom: 20px
  a
    color: inherit

.podcast-image
  background-color: #CCCCCC
  width: 100%
  position: relative
  cursor: pointer
  height: 100%
  object-fit: cover
  aspect-ratio: 16/9

  .play-container
    position: absolute
    width: 100%
    vertical-align: middle
    top: 50%
    margin-top: -3rem
    text-align: center

    i
      color: #FFF
      font-size: 5rem

.podcast-title
  font-weight: 600
  cursor: pointer
  text-decoration: none

</style>
<script>
  import goToDescriptionMixin from "../../mixins/goToDescriptionMixin"

  export default {
    props: {
      podcasts: Array,
      version: Number || undefined
    },
    mixins: [
      goToDescriptionMixin
    ],
    data () {
      return {}
    },
    methods: {
      cardCover (podcast) {
        if (podcast.image && podcast.image != '') {
          return podcast.image
        } else {
          return "https://tool.momice.com/include/momice/defaults/images/news_page_type_podcast.png";
        }
      },
      goTo(podcast) {
        return { name: 'page', params: { pageId: podcast.page_id, param1: podcast.id }}
      }
    }
  }
</script>
