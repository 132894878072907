<template>
	<div class="news-card header">
	  <div class="news-card title">
	    <h3>{{ title }}</h3>
	  </div>
	  <div class="news-card type-icon">
	    <i :class="typeIcon"></i>
	  </div>
	</div>
</template>
<style lang="sass" type="text/sass" scoped>
.header
	min-height: 57px
	.title
		float: left
		padding: 14px 50px 14px 16px
		h3
			margin: 0
			text-align: left
	.type-icon
		position: absolute
		right: 19px
		top: 16px
</style>
<script>
	export default {
		props: {
			data: Object,
			title: String
		},
		data() {
			return {
				iconsConv: {
					podcast: 'speakers',
					regular: 'menu'
				}
			}
		},
		computed: {
			typeIcon() {
				if(this.iconsConv[this.data.type] !== undefined ) {
					return 'icon-'+this.iconsConv[this.data.type];
				}else {
					return 'icon-'+this.data.type;
				}
			}
		}
	}
</script>
