<template>
  <div>
    <transition name="fade" v-if="shouldOpenPopup() && !isClosed && isActive">
      <div class="add-to-homescreen">
        <div class="popup">
            <img v-if="favicon" :src="favicon" class="ath-icon-main" role="presentation" alt="">
            <!-- iPhone Mobile Safari-->
            <div v-if="device.model === 'iPhone' && browser.name === 'Mobile Safari'">
              <p class="instructions" v-html="getMessageFor('ios', 'safari')"></p>
            </div>
            <!-- iPad Mobile Safari-->
            <div v-else-if="device.model === 'iPad' && browser.name === 'Mobile Safari'">
              <p class="instructions" v-html="getMessageFor('ios', 'safari')"></p>
            </div>
            <!-- Android Chrome -->
            <div v-else-if="os.name === 'Android' && browser.name === 'Chrome'">
              <p class="instructions" v-html="getMessageFor('android', 'chrome')"></p>
            </div>
            <!-- Close button -->
            <span @click="closePopup()" class="icon-false-close close"></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapStores } from 'pinia'
  import { useDevice } from '@/stores/device'
  import { useApp } from '@/stores/app'
  import { useAppTranslations } from '@/stores/app-translations'

  export default {
    props: {
      debug: {
        default: false,
        required: false,
        type: Boolean
      },
      favicon: {
        default: null,
        required: false,
        type: String
      },
      wait: {
        default: 4000,
        required: false,
        type: Number
      },
      storageId: {
        default: 'mm-add-to-homescreen',
        required: false,
        type: String
      }
    },
    data() {
      let { protocol } = window.location
      return {
        icons: {
          android: '/static/img/add-to-homescreen/action-icon-android.png',
          ios: '/static/img/add-to-homescreen/action-icon-ios7.png'
        },
        isActive: false,
        isClosed: false,
        isHttps: protocol.split(':')[0] === 'https',
        browser: null,
        device: null,
        os: null,
        ath: {
          timestamp: null,
          daysAgo: null,
          isClosed: null
        }
      }
    },
    computed: {
      ...mapStores(useDevice, useApp, useAppTranslations),
      event () {
        return this.appStore.event
      }
    },
    mounted() {
      this.browser = this.deviceStore.browser
      this.device = this.deviceStore.device
      this.os = this.deviceStore.os
      let ath = this.getStorage()
      if (ath.daysAgo >= 30 || !ath.isClosed) {
        this.openPopup()
      }
      this.clickListener = window.addEventListener('click', this.handleClick)
    },
    beforeUnmount() {
      window.removeEventListener(this.clickListener)
    },
    methods: {
      handleClick(e) {
        if (e.target && !e.target.classList.contains('popup')) {
          this.closePopup()
        }
      },
      shouldOpenPopup() {
        if (!this.device) {
          return false
        }
        let isTabletOrMobile = this.device.type === 'mobile' || this.device.type === 'tablet'

        if (isTabletOrMobile && this.event.homescreen_plugin_active) {
          switch (true) {
            case this.browser.name === 'Chrome' && this.os.name === 'Android':
              return true
            case this.browser.name === 'Mobile Safari' && this.os.name === 'iOS':
              return true
            default:
              return false
          }
        }
        return false
      },
      getMessageFor(os, browser) {
        switch (true) {
          case os === 'android' && browser === 'chrome':
            let androidIcon = `<img style="width: 5px;margin: 0px 5px;" src="${this.icons.android}" class="ath-icon android" />`
            let androidMessage = this.appTranslationsStore.translations['addtohomescreen_android']|| null
            return androidMessage ? androidMessage.replace('%icon', androidIcon) : ''
          case os === 'ios' && browser === 'safari':
            let iosIcon = `<img style="width: 25px;margin: 0px 5px;" src="${this.icons.ios}" class="ath-icon ios" />`
            let iosMessage = this.appTranslationsStore.translations['addtohomescreen_ios'] || null
            return iosMessage ? iosMessage.replace('%icon', iosIcon) : ''
        }
      },
      setStorage() {
        let athObj = {
          timestamp: Date.now(),
          isClosed: true
        }
        localStorage.setItem(this.storageId, JSON.stringify(athObj))
      },
      getStorage() {
        let ath = JSON.parse(localStorage.getItem(this.storageId))
        if (ath) {
          ath.daysAgo = this.getDaysAgo(ath.timestamp)
          this.ath = ath
          return ath
        } else {
          return {}
        }
      },
      getDaysAgo(timestamp) {
        let now = moment()
        let then = moment(timestamp)
        let daysAgo = now.diff(then, 'days')
        return daysAgo
      },
      closePopup() {
        this.isClosed = true
        this.setStorage()
      },
      openPopup() {
        setTimeout(() => {
          this.isActive = true
        }, this.wait)
      },
      log(...messages) {
        if (this.debug) {
          for (let message of messages) {
            console.debug(message)
          }
        }
      }
    }
  }
</script>

<style lang="sass" type="text/scss">
.fade-enter-active, .fade-leave-active
  transition: opacity .2s
.fade-enter, .fade-leave-to
  opacity: 0
.add-to-homescreen
  background: rgba(0, 0, 0, 0.5)
  position: fixed
  z-index: 10000
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #000
  .instructions
    small
      color: grey
      font-size: 14px
      margin-top: 10px
      display: block
  .ath-icon-main
    max-width: 75px
    margin-bottom: 10px
    border-radius: 8px
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px
    border: 1px solid #ababab
  .close
    position: absolute
    right: 10px
    top: 10px
  .popup
    max-width: 450px
    text-align: center
    position: fixed
    background: white
    bottom: 30px
    left: 50%
    transform: translateX(-50%)
    padding: 20px 30px
    border-radius: 8px
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 60px, rgba(0, 0, 0, 0.22) 0px 15px 20px
    z-index: 100000
    &::after
      content: ""
      position: absolute
      top: 100%
      left: 0
      right: 0
      margin: 0 auto
      width: 0
      height: 0
      border-top: solid 10px #ffffff
      border-left: solid 10px transparent
      border-right: solid 10px transparent
    p
      color: #000
  @include media-breakpoint-down(md)
    .popup
      max-width: 750px
      left: 10px
      right: 10px
      bottom: 15px
      transform: translateX(0%)
</style>
