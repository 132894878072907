<template>
  <div class="qa-access-container">
    <div class="qa-access-qrcode">
      <QRCode class="qrImage" :value="programItem.url" :background="`${backgroundColor}`" :size="620"/>
    </div>
    <div class="qa-access-instructions">
      <h3 v-html="this.translate('qa_access_new_instructions')"></h3>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.qa-access-container
  padding: 20px
  text-align: center

.qa-access-qrcode
  width: 130px
  height: 130px
  margin: 0 auto
  .qrImage
    padding: 0px
    border: 0px

.qa-access-instructions
  padding-top: 40px

</style>
<script>
import QRCode from '@/components/Misc/QRCode'
import { mapStores } from 'pinia'
import { useAppTemplate } from '@/stores/app-template'

export default {
  props: {
    programItem: Object
  },
  computed: {
    ...mapStores(useAppTemplate),
    backgroundColor() {
      return this.appTemplateStore.get('colors.4')
    }
  },
  methods: {

  },
  components: {
    QRCode
  }
}
</script>
