<template>
    <div id="app-menu-1" :style="appMenuStyle">
        <div class="logo-container" :style="logoContainerStyle">
            <div class="logo" v-if="logo_container_height !== false"></div>
            <img v-else :src="backgroundImage" style="width: 100%;" role="presentation" alt="">
        </div>
        <component :is="'style-'+itemStyle" class="menu-container" :class="`style-${itemStyle}`" :pages="pages"></component>
    </div>
</template>

<script>
  import Style1 from "./ItemStyles/Style1"
  import Style2 from "./ItemStyles/Style2"
  import Style3 from "./ItemStyles/Style3"
  import Style4 from "./ItemStyles/Style4"
  import { mapStores } from 'pinia'
  import { useAppPages } from "@/stores/app-pages"
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    components: {
      'style-1': Style1,
      'style-2': Style2,
      'style-3': Style3,
      'style-4': Style4
    },
    computed: {
      ...mapStores(useAppPages, useAppTemplate),
      pages () {
        return this.appPagesStore.menu
      },
      itemStyle () {
        return this.appTemplateStore.get('app.options.1.items_style')
      },
      logo_container_height () {
        if (this.options) {
          return this.options.logo_container_height || false
        }
      },
      backgroundImage () {
        return this.options.logo
      },
      logoContainerStyle () {
        if (this.logo_container_height === false) {
          return {
            height: 'auto',
            padding: '0'
          }
        }
      },
      options () {
        return this.appTemplateStore.get('app').options['1']
      },
      fromTool () {
        let { tool = false } = this.$route.query
        if (tool && (tool === 'true' || tool === true)) {
          return true
        } else {
          return false
        }
      },
      appMenuStyle () {
        if (this.fromTool) {
          return {
            overflow: 'hidden'
          }
        }
      }
    }
  }
</script>


<style lang="sass" type="text/sass" scoped>
#app-menu-1
  position: absolute
  z-index: 8999
  top: 0
  left: 0
  width: 100%
  height: 100vh
  overflow: auto

  .logo-container
    .logo
      width: 100%
      height: 100%
      background-size: contain
      background-repeat: no-repeat

  .menu-container
    font-size: 0
</style>
