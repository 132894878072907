<template>
  <div class="col-12">
    <div class="stream row" :class="this.stream_status" v-on:mouseover="mouseOver" v-on:mouseout="mouseOut" >
      <div class="stream-workshop-error-overlay container" v-if="!this.isFinished() && this.stream.workshop_error">
        <div class="row">
          <div class="hidden-md col-1 col-md-2">
          </div>
          <div class="col-12 col-md-10 item-content">
            <h2>&nbsp;</h2>
            <p class="bold">{{ this.translate('stream_no_workshop_registered') }}</p>
          </div>
        </div>
      </div>
      <div class="stream-finished-overlay" v-if="this.isFinished() || !this.hasPermissionToJoin()">
      </div>
      <div class="stream-no-permission-overlay container" :class="{ active: hover }" v-if="!this.hasPermissionToJoin() && !this.isFinished()">
        <div class="row">
          <div class="hidden-md col-1 col-md-2">
          </div>
          <div class="col-12 col-md-10 item-content">
            <h2>{{ this.translate('live_stream_no_permission_header') }}</h2>
            <p>{{ this.stream_private_translation }}</p>
          </div>
        </div>
      </div>
      <div class="col-3 col-md-2 item-time">
        <p class="mrb-0 time-block"><strong>{{ this.time_start }}</strong></p>
        <p class="fw-300 time-block">{{ this.time_end }}</p>
      </div>
      <div class="col-9 col-md-10 item-content">
        <div class="row">
          <div class="col-md-8">
            <h3 class="title mrb-0">{{ stream.title }}</h3>
            <p class="stream-info-workshop" v-if="stream.workshop">{{ stream.workshop }}</p>
            <p class="stream-info" v-html="stream.description"></p>
            <a class="toggle-stream-content" @click="toggleContentVisibility()" v-if="stream.content">
              <span v-if="!this.content_visible">{{ translate('more_information') }}</span>
              <span v-if="this.content_visible">{{ translate('less_information') }}</span>
            </a>
          </div>
          <div class="col-md-4 item-status-col">
            <div class="item-status row">
              <div class="item-status-indicator-col col-12 col-lg-6">
                <div :title="this.status" :class="indicator" >
                  <span>{{ this.status_translation }}</span>
                </div>
              </div>
              <div class="item-access col-12 col-lg-6">
                <div :class="this.item_access_icon" class="item-access-icon"></div>
                <span>{{ this.public_status }}</span>
              </div>
            </div>
            <AdvancedButton :loading="false" :disabled="disabled" v-if="this.canJoin()" class="mm-btn-stream btn btn-1" @clickEvent="join()">
              <template v-slot:loading>
                <span>{{ 'loading' }}</span>
              </template>
              <template v-slot:default>
                <span>{{ button_cta }}</span>
              </template>
            </AdvancedButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.stream.content" class="stream-content-row row" :class="{ active: content_visible }">
      <div v-if="this.content_visible" class="content stream-content" v-html="stream.content"></div>
    </div>
  </div>
</template>
<style lang="scss" type="text/scss" scoped>
  .stream {
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    min-height: 150px;

    .stream-finished-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background-color: #ffffff;
      z-index: 2;
      left: 0px;
      right: 0px;
    }

    .stream-no-permission-overlay, .stream-workshop-error-overlay {
      background-color: #333333;
      border: 1px solid #333333;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      opacity: 0.7;
      display: none;
      left: 0px;
      right: 0px;

      &.active {
        display: block;
      }

      h2 {
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0px;
      }

      p {
        color: #ffffff;
      }
    }

    .stream-workshop-error-overlay {
      display: block;
    }

    .item-time {
      border-right: 1px solid;
      padding-top: 30px;
    }

    .item-content {
      padding: 35px;
      padding-top: 30px;
      padding-bottom: 30px;

      h3 {
        text-align: left;
      }

      h3.title {
        font-weight: 600;
      }

      .stream-info-workshop {
        font-style: italic;
      }

      .stream-info {
        margin-bottom: 0px;
      }

      .toggle-stream-content {
        padding-top: 3px;
        display: block;
        cursor: pointer;
      }

      .item-status-col {
        padding-left: 0px;
        padding-right: 0px;

        .item-status {
          margin-bottom: 20px;
          margin-top: 8px;

          .item-indicator {
            height: 15px;
            width: 15px;
            border: 0px;
            border-radius: 50%;

            span {
              font-size: 80%;
              margin-left: 20px;
              position: absolute;
              line-height: 80%;
              margin-top: 2px;
            }
          }

          .item-indicator-join {
            background-color: green;
          }
          .item-indicator-not-started {
            background-color: yellow;
          }
          .item-indicator-finished {
            background-color: orange;
          }
          .item-indicator-no-permission {
            background-color: red;
          }

          .item-access {

            .item-access-icon {
              width: 20px;
              height: 20px;
              background-size: contain;
              background-position: left;
              background-repeat: no-repeat;
              float: left;
              margin-top: -2px;
            }

            span {
              font-size: 80%;
              margin-left: 5px;
              position: absolute;
              line-height: 91%;
              margin-top: 2px;
            }
          }
        }
      }
    }

    @media (max-width: 1279px) {
      .item-status-col {
        padding-left: 20px !important;
      }

      .item-status-indicator-col {
        padding-bottom: 20px;
      }
    }
  }

  .stream-content-row {
    border-right: 1px solid;
    border-left: 1px solid;

    &.active {
      border-bottom: 1px solid;
    }

    .stream-content {
      padding: 20px;
      padding-bottom: 0px;
    }
  }

  @media (max-width: 1279px) {
    .stream-content-row {
      .stream-content {
        padding-right: 40px;
      }
    }
  }
</style>
<script>
  import { AdvancedButton } from '@/components/Misc';
  import Moment from "moment";
  import {env} from "../../env";
  import { mapStores } from 'pinia'
  import { useVisitor } from '@/stores/visitor'
  import { useApp } from '@/stores/app'

  export default {
    props: {
      stream: Object,
    },
    components: {
      AdvancedButton
    },
    data () {
      return {
        lastPressedButton: null,
        hover: false,
        status: null,
        backend: null,
        time_start: null,
        time_end: null,
        content_visible: false,
        real_time: Moment()
      }
    },
    created () {
      // Set backend
      this.backend = env.API_URL;

      // interval for real_time variable
      setInterval(() => this.real_time = Moment(), 5000)

      // Determine call to action label
      if(this.canJoin()) {
        // Visitor can join based on time and permissions
        this.status = 'join'
      } else {
        if(this.hasPermissionToJoin()) {
          if(this.isFinished()) {
            // Stream is finished
            this.status = 'finished'
          } else {
            // Stream not started yet
            this.status = 'not-started'
          }
        } else {
          if(this.isFinished()) {
            // Stream is finished
            this.status = 'finished'
          } else {
            this.status = 'no-permission'
          }
        }
      }

      // Get times
      this.time_start = Moment(this.stream.start_date).format('HH:mm');
      this.time_end = Moment(this.stream.end_date).format('HH:mm');
    },
    computed: {
      ...mapStores(useVisitor, useApp),
      inited () {
        return this.visitorStore.inited
      },
      invite () {
        return this.visitorStore.invite
      },
      registered () {
        let registered = false;
        if(this.inited) {
          if(this.invite.ticket) {
            return true;
          }
        }
        return registered;
      },
      event () {
        return this.appStore.event
      },
      disabled () {
        // Determine button status
        if(this.canJoin()) {
          // Visitor can join based on time and permissions
          return false
        } else {
          return true
        }
      },
      button_cta () {
        return this.translate('choose_stream')
      },
      indicator () {
        // Define indicator class
        return 'item-indicator item-indicator-' + this.status
      },
      item_access_icon () {
        if(this.stream.public == 'yes') {
          return 'item-access-public'
        } else {
          return 'item-access-private'
        }
      },
      stream_status () {
        // Define indicator class
        return 'stream-' + this.status
      },
      status_translation () {
        // Get translation for stream status
        return this.translate('live_stream_status_label_' + this.status)
      },
      stream_private_translation () {
        if(this.event.registration_code === false) {
          return this.translate('live_stream_no_permission_open_content')
        } else {
          return this.translate('live_stream_no_permission_closed_content')
        }
      },
      stream_link () {
        var stream_link = this.backend + this.stream.stream_url;
        if(this.inited) {
          stream_link = stream_link + '/' + this.invite.hash;
        }
        return stream_link;
      },
      public_status () {
        if(this.stream.public == 'yes') {
          return this.translate('stream_access_public')
        } else {
          return this.translate('stream_access_private')
        }
      }
    },
    methods: {
      // Does the visitor have permissions to join the livestream
      hasPermissionToJoin() {
        if(this.stream.public == 'yes') { // Stream is public so everyone can join
          return true
        } else if(this.stream.public == 'no' && this.registered) { // Stream is private, but registration is on website
          return true
        } else { // In all other scenario's the stream is not available
          return false
        }
      },
      isTimeToJoin() {
        var walk_in_date = Moment(this.stream.walk_in_date);

        // Check if walkin time is reached
        if(walk_in_date < this.real_time) {
          // Check if end date is reached
          if(this.isFinished()) {
            this.status = 'finished';
            return false
          } else {
            // End date is not reached
            return true
          }
        } else {
          // Not yet started
          return false
        }
      },
      isFinished() {
        var end_date = Moment(this.stream.end_date);

        // Check if end date is reached
        if(end_date < this.real_time) {
          return true
        } else {
          // End date is reached
          return false
        }
      },
      // Can someone join based on permissions and time
      canJoin() {
        if(this.hasPermissionToJoin()) {
          // Validate times
          if(this.isTimeToJoin()) {
            this.status = 'join';
            return true;
          } else {
            return false;
          }
        } else {
          // No permissions
          return false;
        }
      },
      // Join stream
      join() {
        if(this.canJoin()) {
          window.open(this.stream_link, "_blank");
        }
      },
      mouseOver: function() {
        this.hover = true;
      },
      mouseOut: function() {
        this.hover = false;
      },
      toggleContentVisibility () {
        this.content_visible = !this.content_visible
        console.log(this.content_visible);
      },
    }
  }
</script>
