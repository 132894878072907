<template>
    <div class="mm-contacts">
        <div class="row justify-content-md-center" v-for="(chunk, chunkKey) in rows">
            <div class="col-12 contact" :class="{'col-lg-3 col-md-3': contact.contact}" v-for="(contact, key, index) in chunk">
                <div class="row g-0" v-if="contact.contact">
                    <div class="details col-8 order-1 order-md-2 col-md-12 text-start text-md-center">
                      <div>
                        <h3>
                          <router-link
                            class="mm-color-text"
                            :to="{ name: 'page', params: { pageId: contact.parent_id, param1: contact.id }}"
                          >
                            {{ contact.name }}
                          </router-link>
                        </h3>
                      </div>
                      <p class="mm-color-text">
                        {{ contact.function }}<span v-if="contact.function && contact.company">, </span>{{ contact.company }}
                      </p>
                    </div>
                    <div class="col-4 order-2 order-md-1 col-md-12">
                        <ImageOrInitials
                          :initials="contact.initials"
                          :to="{ name: 'page', params: { pageId: contact.parent_id, param1: contact.id }}"
                          :image="contact.image"
                          :circle="contact.circle"
                          :resource="{ type: 'contact', details: contact.name }"
                        />
                    </div>
                    <div class="col-sm-12 order-3 d-md-none" v-if="(chunk.length-1 !== key) || (chunk.length-1 === key && (rows.length-1 !== chunkKey))">
                      <hr/>
                    </div>
                </div>
                <div v-else class="content mm-html contact-content" v-html="contact.content"></div>
                <hr class="col-12 d-md-none" v-if="!contact.contact" />
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: remove when we do not use it in the contacts widgets (homepage) anymore
  // TODO: and use the resource overview instead
  import { rowify } from 'lib'
  import ImageOrInitials from '../ImageOrInitials'

  export default {
    name: 'ContactOverview',
    components: {
      ImageOrInitials
    },
    props: {
      contacts: Array
    },
    computed: {
      rows () {
        return rowify(this.contacts, 'contact')
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.content.mm-html.contact-content
  padding-bottom: 0px
.mm-contacts
  @include media-breakpoint-up(md)
    .row:not(:last-child)
      padding-bottom: 40px
.contact
  display: inline-block

  .details
    margin-top: 20px

    p
      margin: 0
      line-height: 1.3rem

    h3
      cursor: pointer
      margin-bottom: 5px
      line-height: 1.3rem
      font-weight: 600
      text-decoration: none

  @include media-breakpoint-down(md)
    .details
      margin-top: 0
      padding-right: 20px

    h3
      font-weight: 600
      cursor: pointer
      text-decoration: none

    p
      display: inline-block
</style>
