<template>
	<div class="news-card comments">
		<div v-for="(p, i) in comments" :key="i">
			<div class="comment-data" data-cy="new-comment-data">
				<i class="icon-avatar comment-avatar">0</i>
				<b class="comment-author">{{ p.firstname }} {{ p.lastname }}</b>
				<span class="comment-date" style="float: right;">{{ dateFormat(p.date) }}</span>
			    <div class="comment-text" data-cy="new-comment-text">{{ p.comment }}</div>
			</div>
	  </div>
    <div class="news-card new-comment" v-if="isRegistration">
      <button type="button" class="btn btn-1 new-comment-btn" @click="postComment()"  style="float: right; height: 44px;" data-cy="new-comment">Post</button>
      <div style="overflow: hidden; ">
        <input 
          :style="{color: colors('7')}"
          type="text"
          class="form-control new-comment-input"
          v-model="input_comment"
          :placeholder="translate('news_make_comment')"
          data-cy="new-input-comment"
        />
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>

.comments
  border-top: 1px solid rgba(0, 0, 0, 0.12)
  clear: both

  .comment-data
    padding: 16px
    padding-bottom: 10px
    width: 100%
    .comment-avatar
      float: left
      padding: 5px
      padding-left: 0px
      display: none
    .comment-author
      float: left
    .comment-date
      float: right
    .comment-text
      clear: right

  .new-comment
    padding: 16px

</style>
<script>
	import Moment from "moment"
	import http from '../../http'
  import { mapStores } from 'pinia'
  import { useAppNews } from '@/stores/app-news'

	export default {
		props: {
			data: Object
		},
		data () {
      return {
        input_comment: '',
        comments: []
      }
    },
    created () {
      this.comments = this.data.comments;
    },
    computed: {
      ...mapStores(useAppNews),
      isRegistration() {
        return this.appNewsStore.isRegistration
      }
    },
		methods: {
			dateFormat(date) {
        return Moment(date).fromNow();
      },
      postComment () {
        if(this.input_comment != '') {
          http.post('/news/comment', { news_id: this.data.news_id, comment: this.input_comment }).then((response) => {
            if(response.data.created) {
              this.comments = response.data.news.comments;
              this.input_comment = '';
              this.$emit("newComment", response.data.news.comments.length);
            }
          }).catch(e => {
            console.log(e);
          })
        }
      }
		}
	}
</script>
