<template>
    <div>
        <div class="row navigation" v-if="indexRoute">
            <div class="col-12 text-center">
                <span v-if="previousRoute">
                    <router-link
                      :to="previousRoute"
                      :aria-label="translate('previous')"
                    >
                      <i class="icon-arrow-left" aria-hidden="true"></i>
                    </router-link>
                </span>
                <span v-else>
                    <i class="icon-arrow-left not_visible" aria-hidden="true"></i>
                </span>

                <router-link
                  :to="indexRoute"
                  :aria-label="translate('overview')"
                >
                  <i class="icon-overview index" aria-hidden="true"></i>
                </router-link>

                <span v-if="nextRoute">
                    <router-link
                      :to="nextRoute"
                      :aria-label="translate('next')"
                    >
                      <i class="icon-arrow-right" aria-hidden="true"></i>
                    </router-link>
                </span>
                <span v-else>
                    <i class="icon-arrow-right not_visible" aria-hidden="true"></i>
                </span>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
a
  text-decoration: none !important

.navigation
  padding: 20px 0px
  i
    cursor: pointer
    font-size: 2rem

  i.index
    padding: 0px 10px

  .not_visible
    visibility: hidden
</style>
<script>
  export default {
    props: {
      previousRoute: Object,
      nextRoute: Object,
      indexRoute: Object
    },
    data () {
      return {}
    }
  }
</script>
