<script setup>
import { onMounted, ref, watch } from 'vue'
import { useTranslations } from '@/composables/translations'
import { useAppSurvey } from '@/stores/app-survey'
import { storeToRefs } from "pinia";

const appSurveyStore = useAppSurvey()

const { answerState } = storeToRefs(appSurveyStore)

const { translate } = useTranslations()

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  },
  validation: Boolean
})

const answerSelected = ref(null)
const answerOtherSelected = ref(null)
const options = ref([])

onMounted(() => {
  options.value = [ ...props.question.options ]

  if (props.question.other) {
    options.value.push({
      option: translate('survey_other'),
      option_id: -1
    })
  }

  const question = { ...props.question }

  answerSelected.value = question.answer ? question.answer.option_id : null
})

watch(() => answerSelected.value, (val) => {
  if (val === -1) {
    answerState.value = {
      option_id: -1,
      value: null
    }
  } else {
    answerState.value = {
      option_id: val,
      value: null
    }
  }
})

watch(() => answerOtherSelected.value, (val) => {
  answerState.value = {
    option_id: -1,
    value: val
  }
})
</script>

<template>
  <div>
    <select
      id="survey-question"
      v-model="answerSelected"
      class="form-select bg-white text-dark"
      :class="{ error: validation === false}"
      data-cy="survey-select"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.option_id"
      >
        {{ option.option }}
      </option>
    </select>
    <div
      v-if="props.question.other && answerSelected === -1"
      class="form-check"
    >
      <input
        v-model="answerOtherSelected"
        type="text"
        class="form-control"
        autocomplete="off"
      >
    </div>
  </div>
</template>

<style lang="scss" type="text/scss" scoped>
.form-check {
  margin-top: 10px;
}
</style>


