<template>
  <div>
    <label id="prevPage" @click="previousPage()" role="link" tabindex="0" @keypress.enter="previousPage()" data-cy="new-go-timeline"><i class="icon-arrow-left align-middle return-link"></i> Timeline</label>
    <div class="justify-content-center">
      <div class="news-wrapper">
        <news-header :data="post" :title="post.title"></news-header>
        <div class="news-card body">
          <div v-html="post.extra.embed"></div>
          <div class="news-card title">{{ post.extra.title }}</div>
        </div>
        <news-footer :data="post" :isOverview="false"></news-footer>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
#prevPage
  cursor: pointer

.news-wrapper
  width: 100%
  margin: 20px 0px
  display: flex
  position: relative
  border-radius: 6px
  flex-direction: column
  border: 1px solid rgba(0, 0, 0, 0.12)

.title
  padding: 0px

.body
  padding: 16px

#prevPage
  @media (max-width: 1279px)
    display: none

</style>
<script>
  import Header from "../NewsHeader.vue"
  import Footer from "../NewsFooter.vue"

  export default {
    props: {
      post: Object
    },
    methods: {
      previousPage () {
        this.$router.go(-1)
      }
    },
    components: {
      'news-header': Header,
      'news-footer': Footer
    }
  }
</script>
