<template>
  <div>
    <label id="prevPage" @click="previousPage()" role="link" tabindex="0" @keypress.enter="previousPage()" data-cy="new-go-timeline"><i class="icon-arrow-left align-middle return-link"></i> Timeline</label>
    <div class="justify-content-center">
      <div class="news-wrapper">
        <div class="news-card body">
          <div v-if="voted" class="news-card poll-confirm">
            <div v-if="results" class="col-5 news-card poll-results">
              <div v-for="(opt, index) in poll.options" :key="index">
                <div class="row content justify-content-center">
                  <span class="col-12 col-md-6">{{ opt.name }}</span>
                  <span class="col-12 col-md-6">{{ opt.percentage }}%</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-html="poll.confirmation_text"></div>
              <button type="button" class="btn btn-1 poll-option" @click="showResults()">View results</button>
            </div>
          </div>
          <div v-else>
            <h2>{{ poll.question }}</h2>
            <div v-for="(opt, index) in poll.options" :key="index">
              <button type="button" class="btn btn-1 poll-option" @click="postVote(opt.option_id,opt.poll_id)">
                {{ opt.name }}
              </button>
            </div>
          </div>
        </div>
        <news-footer :data="post" :isOverview="false"></news-footer>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '../../../http'
  import Footer from "../NewsFooter.vue"

  export default {
    props: {
      post: Object
    },
    data () {
      return {
        poll: this.post.extra,
        poll_done: false,
        results: false,
        voted: false
      }
    },
    created () {
      if(this.post.extra.visitor_vote !== null){
        this.voted = true;
        this.results = true;
      }
    },
    methods: {
      previousPage () {
        this.$router.go(-1)
      },
      postVote(option_id,poll_id) {
        http.post('/poll/vote', { option_id: option_id, poll_id: poll_id }).then((response) => {
          this.voted = true;
          this.poll = response.data.poll
        }).catch(e => {
          console.log(e);
        })
      },
      showResults() {
        this.results = true;
      }
    },
    components: {
      'news-footer': Footer
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
#prevPage
  cursor: pointer

.news-wrapper
  width: 100%
  margin: 20px 0px
  display: flex
  position: relative
  border-radius: 6px
  flex-direction: column
  border: 1px solid rgba(0, 0, 0, 0.12)

.body
  padding: 16px
  text-align: center

.poll-option
  margin-bottom: 5px
  min-width: 250px

#prevPage
  @media (max-width: 1279px)
    display: none

</style>
