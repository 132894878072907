<template>
  <div>
    <img v-if="image" :src="image" class="image" :alt="resourceDetails"/>
    <span v-else-if="initials" key="initials" class="initials" aria-hidden="true" >
      <span v-for="(char, index) in chars" :key="index">
        {{ char }}
      </span>
    </span>
  </div>
</template>

<script>

export default {
  name: "ImageInitials",
  props: {
    image: {
      type: String,
      required: false
    },
    initials: {
      type: String,
      required: false
    },
    resourceDetails: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    chars () {
      if (this.initials) {
        return this.initials.split('').filter(c => c !== ' ')
      }
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
.image
  width: 100%
  aspect-ratio: 1/1
  object-fit: cover

span.initials
  z-index: 1
  text-align: center
  font-size: 5.0rem
  font-weight: 600

@include media-breakpoint-down(lg)
  span.initials
    font-size: 3.0rem

</style>
