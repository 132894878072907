<template>
    <div
      class="image-initials-container"
      v-observe-visibility="visibilityChange"
      :class="{'image-link': hasLink, 'has-image': image, 'circle': circle}"
    >
      <router-link v-if="to && to.name" :to="to" :aria-label="goToDescription(resourceType, resourceDetails)">
        <ImageInitials :image="image" :initials="initials" :resourceDetails="resourceDetails" />
      </router-link>
      <div v-else key="default">
        <ImageInitials :image="image" :initials="initials" :resourceDetails="resourceDetails" />
      </div>
    </div>
</template>

<script>
  import ImageInitials from "./ImageOrInitials/ImageInitials"
  import goToDescriptionMixin from "../mixins/goToDescriptionMixin"

  export default {
    components: {
      ImageInitials
    },
    props: {
      to: {
        type: Object,
        required: false
      },
      image: {
        type: String,
        required: false
      },
      initials: {
        type: String,
        required: false
      },
      circle: {
        type: Boolean,
        required: false,
        default: false
      },
      resource: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    mixins: [
      goToDescriptionMixin
    ],
    data () {
      return {
        isVisible: false
      }
    },
    computed: {
      hasLink () {
        return this.to && Object.keys(this.to).length !== 0
      },
      resourceType() {
        return this.resource ? this.resource.type : ''
      },
      resourceDetails() {
        return this.resource ? this.resource.details : ''
      }
    },
    methods: {
      visibilityChange (isVisible, enrty) {
        if (isVisible) {
          this.isVisible = isVisible
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div.image-initials-container
  width: 100%
  position: relative
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  border-style: solid
  border-width: 1px

  &.has-image
    border-width: 0

  &.image-link
    cursor: pointer

  &:after
    content: ''
    display: table
    padding-bottom: 100%

  & a
    text-decoration: none
    color: inherit

.circle
  border-radius: 50%
  overflow: hidden

.has-image
  border: 0
</style>
