<script setup>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import ResourceDetailCard from './ResourceDetailCard.vue'
import ResourceDetailNavigation from './ResourceDetailNavigation.vue'

const router = useRouter()
const route = useRoute()

const props = defineProps({
  page: {
    type: Object,
    default: () => {}
  },
  resourceElements: {
    type: Array,
    default: () => []
  },
  resourceDetails: {
    type: Object,
    default: () => {}
  }
})

const currentIndex = computed(() => {
  return props.resourceElements.findIndex(p => p.id === parseInt(route.params.param1))
})

const overviewRoute = computed(() => {
  return props.page ? {
    name: 'page',
    params: {
      pageId: props.page.page_id
    }
  } : null
})

const previousIndex = computed(() => {
  return currentIndex.value === 0 ? null : currentIndex.value - 1
})

const previousRoute = computed(() => {
  return previousIndex.value !== null ? {
    name: 'page',
    params: {
      pageId: props.page.page_id,
      param1: props.resourceElements[previousIndex.value].id
    }
  } : null
})

const nextIndex = computed(() => {
  return currentIndex.value === props.resourceElements.length - 1 ? null : currentIndex.value + 1
})

const nextRoute = computed(() => {
  return nextIndex.value ? {
    name: 'page',
    params: {
      pageId: props.page.page_id,
      param1: props.resourceElements[nextIndex.value].id
    }
  } : null
})

const previous = (e) => {
  if (e && e.preventDefault) {
    e.preventDefault()
  }

  if (previousIndex.value) {
    router.push(previousRoute.value)
  }
}

const next = (e) => {
  if (e && e.preventDefault) {
    e.preventDefault()
  }

  if (nextIndex.value) {
    router.push(nextRoute.value)
  }
}
</script>

<template>
  <div 
    v-if="props"
    v-touch:swipe.left="previous"
    v-touch:swipe.right="next"
    class="row justify-content-lg-center" 
    @keyup.prevent.left="previous"
    @keyup.prevent.right="next"
  >
    <ResourceDetailCard :resource-details="props.resourceDetails" />
  </div>
  <div class="row justify-content-lg-center">
    <div class="col-lg-8 mm-col-hr">
      <hr>
    </div>
  </div>
  <ResourceDetailNavigation
    v-if="props && props.resourceElements.length > 1"
    :overview-route="overviewRoute"
    :next-route="nextRoute"
    :previous-route="previousRoute"
  />
</template>

<style lang="sass" type="text/sass" scoped>

</style>