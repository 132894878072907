<template>
    <div class="not_found_404">
      <div class="container">
        <div class="row">
            <div class="col-sm-2 hidden-xs"></div>
            <div class="col-12 col-sm-8 text-center">
                <h1>{{ translate('404_title') }}</h1>
                <p>{{ translate('404_content') }}</p>
            </div>
            <div class="col-sm-2 hidden-xs"></div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props: {
      status: Object
    }
  }
</script>

<style scoped>
  .not_found_404 {
    padding: 50px 0px;
  }
</style>
