<script setup>
import { inject } from 'vue'
import ImageOrInitials from '@/components/ImageOrInitials.vue'
import SocialMediaIcons from '@/components/SocialMedia/SocialMediaIcons.vue'
import { useUrl } from '@/composables/url'

const { getRootDomainFromUrl } = useUrl()

const service = inject('service')

const props = defineProps({
  resourceDetails: {
    type: Object,
    default: () => {}
  }
})
</script>

<template>
  <div class="col-md-6 col-lg-4">
    <ImageOrInitials
      class="resource-image"
      :image="props.resourceDetails.image"
      :initials="props.resourceDetails.initials"
      :to="{}"
      :circle="props.resourceDetails.circle"
      :resource="{ type: service, details: props.resourceDetails.name }"
    />
  </div>
  <div class="col-md-6 col-lg-4 text-center text-md-start resource-detail">
    <h2 
      class="mm-detail-title"
      data-test="name"
    >
      {{ props.resourceDetails.name }}
    </h2>
    <p data-test="function-company">
      {{ props.resourceDetails.function }}<span v-if="props.resourceDetails.function && props.resourceDetails.company">, </span>{{ props.resourceDetails.company }}
    </p>

    <SocialMediaIcons 
      :social-media-links="props.resourceDetails.icons"
      :owner-name="props.resourceDetails.name"
    />

    <span v-if="props.resourceDetails.phone">
      <a :href="'tel:' + props.resourceDetails.phone" data-test="phone">{{ props.resourceDetails.phone }}</a><br>
    </span>

    <span v-if="props.resourceDetails.email">
      <a
        v-if="props.resourceDetails.email"
        :href="'mailto:' + props.resourceDetails.email"
        target="_blank"
        data-test="email"
      >{{ props.resourceDetails.email }}</a><br>
    </span>
    <a
      v-if="props.resourceDetails.website"
      :href="props.resourceDetails.website"
      target="_blank"
      data-test="website"
    >{{ getRootDomainFromUrl(props.resourceDetails.website) }}</a>
  </div>

  <div v-if="props.resourceDetails.content">
    <div class="row justify-content-lg-center">
      <div class="col-lg-8 mm-col-hr">
        <hr>
      </div>
    </div>
    <div class="row justify-content-lg-center">
      <div
        class="col-lg-8 mm-html mm-details-content"
        v-html="props.resourceDetails.content"
        data-test="content"
      />
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.resource-detail
  a
    color: inherit
    font-weight: 600

  h2
    font-weight: 600

  @include media-breakpoint-down(md)
    h2
      margin-top: 30px

.resource-image
  max-width: 320px
  margin: 0 auto

</style>