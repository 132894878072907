<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 text-center">
                <img :src="plan.image" class="img-fluid" :alt="translate('floorplan_venue')" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <div class="download-wrapper" v-for="download in plan.downloads">
                  <a :href="download.link" target="_blank" class="btn btn-2 download-button">{{ download.button || 'Download' }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.download-wrapper
  margin-bottom: 5px
img
  margin-top: 23px
  padding-bottom: 50px
.download-button
  display: inline-block
</style>
<script>
  export default {
    props: {
      plan: Object
    },
    data () {
      return {}
    }
  }
</script>
