<template>
  <div
    class="row subpage justify-content-center"
    :class="{'is-collapsed': isCollapsed}"
  >
    <div class="col-12 col-sm-8">
      <button
        class="mm-subpage-title bg-color-6"
        tabindex="0"
        role="button"
        :aria-expanded="informationCollapsed"
        @keypress.enter="toggleCollapsed()"
        @click="toggleCollapsed()"
      >
        <h2 class="fs-6"> {{ subpage.title }} </h2>
        <span
          class="mm-subpage-toggle"
          aria-hidden="”true”"
        >
          <i
            v-if="isCollapsed"
            class="icon-arrow-down color-7"
          />
          <i
            v-else
            class="icon-arrow-up color-7"
          />
        </span>
      </button>
      <div
        v-if="!isCollapsed"
        class="mm-subpage-content"
      >
        <div class="mm-subpage-img-wrapper text-center">
          <img
            v-if="subpage.image"
            :src="subpage.image"
            class="img-fluid"
            alt=""
          >
        </div>
        <div
          class="mm-html"
          v-html="subpage.content"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      subpage: {
        type: Object,
        required: true,
        default: null
      }
    },
    data () {
      return {
        isCollapsed: true
      }
    },
    computed: {
      informationCollapsed () {
        return this.isCollapsed ? 'false' : 'true';
      }
    },
    methods: {
      toggleCollapsed(){
        this.isCollapsed = !this.isCollapsed
      }
    }
  }
</script>

<style lang="scss" type="text/scss">
  .subpage {
    padding-bottom: 20px;

    button {
      border: none;
      width: 100%;
      padding-left: 20px;
    }

    div {
      position: relative;
    }

    h2 {
        text-align: left;
        font-weight: 300;
        margin: 0 !important;
    }
    img {
        padding: 20px 0 30px 0;
    }
  }
</style>
