<template>
  <div class="page-unsubscribe">
    <div v-if="ready">
      <div class="container" v-if="!success">
        <div class="row content justify-content-center text-unregister-before">
          <div class="col-12 col-md-8" v-html="text_unregister_before"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <form v-on:submit.prevent="unsubscribe" novalidate>
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <div class="form-group">
                <div class="form-group mm-custom-form">
                  <div class="form-check mm-form-checkbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="mainInvite.hash"
                      :value="mainInvite.hash"
                      :name="mainInvite.hash"
                      v-model="checkedInvites"
                      :disabled="extraInvites.length === 0"
                    />
                    <label class="form-check-label" :for="mainInvite.hash">
                      <p>{{ mainInvite.fullName }} {{ formatEmail(mainInvite.email) }}</p>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea
                  rows="5"
                  class="form-control"
                  v-model="remarks"
                  :placeholder="translate('remarks')"
                ></textarea>
              </div>
              <!-- extra  guests -->
              <div v-if="extraInvites.length > 0">
                <hr class="form-separator" />
                <h3>{{ translate("title_your_guests") }}</h3>
                <p class="mb-4">
                  {{ translate("text_select_uninvite_guests") }}
                </p>
                <div
                  class="extra-guest"
                  v-for="(invite, index) in extraInvites"
                  :key="index"
                >
                  <div class="form-group mm-custom-form">
                    <div class="form-check mm-form-checkbox">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="invite.hash"
                        :value="invite.hash"
                        :name="invite.hash"
                        v-model="checkedInvites"
                      />
                      <label class="form-check-label" :for="invite.hash">
                        <p>
                          {{ invite.fullName }} {{ formatEmail(invite.email) }}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <hr class="form-separator extra-guest-form-separator" />
              </div>
              <!-- submit button -->
              <div class="form-group text-end">
                <button
                  type="button"
                  class="btn btn-1"
                  @click="unsubscribe()"
                  :disabled="checkedInvites.length === 0"
                  data-cy="unsubscribe"
                >
                  {{ translate("unsubscribe") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="container" v-if="success">
        <div class="row content justify-content-center">
          <div class="col-12 col-md-8" v-html="text_unregister_after"></div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row content justify-content-center">
        <div class="col-12 col-md-8" v-html="text_unregister_use_personal_link"></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../http";
import { mapStores } from "pinia";
import { useApp } from "@/stores/app";
import { useVisitor } from "@/stores/visitor";

export default {
  data() {
    return {
      mainInvite: Object,
      remarks: "",
      extraInvites: [], // response data for the invites
      checkedInvites: [], // form results to post
      error: "",
      success: false,
      ready: false,
    };
  },
  computed: {
    ...mapStores(useApp, useVisitor),
    event() {
      return this.appStore.event;
    },
    text_unregister_before() {
      if (this.translationExists("text_unregister_before")) {
        return this.translate("text_unregister_before");
      } else {
        return this.event.text_unregister_before;
      }
    },
    text_unregister_after() {
      if (this.translationExists("text_unregister_after")) {
        return this.translate("text_unregister_after");
      } else {
        return this.event.text_unregister_after;
      }
    },
    text_unregister_use_personal_link() {
      if (this.translationExists("text_unregister_use_personal_link")) {
        return this.translate("text_unregister_use_personal_link");
      } else {
        return this.event.text_unregister_use_personal_link;
      }
    },
    inited() {
      return this.visitorStore.inited;
    },
    invite() {
      return this.visitorStore.invite;
    },
    hash() {
      // The "unsubscribe" route can be called with a "hash" param or if not present we get it from the logged in visitor
      if (this.$route.params.hash) {
        return this.$route.params.hash;
      }

      return this.inited ? this.invite.hash : null;
    },
  },
  created() {
    if (this.hash !== null) {
      http
        .post("/unsubscribe/init", { hash: this.hash })
        .then((response) => {
        
          this.extraInvites = response.data.invites.data;
          
          // add main invite first
          if (!this.$route.params.guestHash) {
            this.checkedInvites.push(this.hash);
            if (response.data.invites.length) {
              response.data.invites.data.forEach((invite) => {
                this.checkedInvites.push(invite.hash);
              });
            }
          } else {
            this.checkedInvites.push(this.$route.params.guestHash);
          }

          this.mainInvite = response.data.main_invite;
          const isAlreadyUnsubscribed =
            response.data.already_unsubscribed ?? false;

            if (isAlreadyUnsubscribed) {
              this.ready = true
              this.success = true
            } else {
              this.ready = this.mainInvite !== null
            }
            
          }).catch((e) => {
            window.handle_error(e, 'unsubscribe get catch')
          })
        }
      },
      methods: {
        unsubscribe () {
          http.post('/unsubscribe/confirm', {
            hash: this.hash,
            remarks: this.remarks,
            checkedInvites: this.checkedInvites
          }).then((response) => {
            this.success = true
          }).catch(e => {
            if (e.response.data.type === 'unsubscribe_unknown') {
              this.error = e.response.data.data.msg
            } else if (e.response.data.type === 'introducee_cant_unsubscribe') {
              this.error = e.response.data.data.msg
            } else {
              window.handle_error(e, 'unsubscribe post catch')
            }
          })
        },
        formatEmail(email) {
          return email !== "" ? `(${email})` : ""
        }
      }
    }
</script>

<style type="text/scss" lang="scss" scoped>
.page-unsubscribe {
  padding-top: 50px;

  .text-unregister-before {
    p {
      padding-bottom: 10px !important;
    }
  }

  .extra-guest {
    margin-bottom: 10px;
    .form-check {
      min-height: auto !important;
      p {
        margin-bottom: 0px;
      }
    }
  }

  .extra-guest-form-separator {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
