<template>
  <div class="icons">
    <a
      v-for="(socialMedia, socialMediaKey) in socialMediaLinksData"
      :key="socialMediaKey"
      :href="socialMedia.url"
      target="_blank"
      :aria-label="ariaLabel(socialMedia.name)"
      class="text-decoration-none"
    >
      <i :class="getIcon(socialMedia.type)" aria-hidden="true"></i>
    </a>
  </div>
</template>

<script>
import serviceOfOwnerMixin from '../../mixins/serviceOfOwnerMixin'

export default {
  name: 'SocialMediaIcons',
  mixins: [
    serviceOfOwnerMixin
  ],
  props: {
    socialMediaLinks: {
      type: Array,
      required: true,
      default: []
    },
    ownerName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    socialMediaLinksData() {
      return this.socialMediaLinks ?? []
    }
  },
  methods: {
    getIcon(socialMediaType) {
      if (socialMediaType.toLowerCase() === 'website') {
        return 'icon-news-updates'
      }

      return `icon-${socialMediaType}-square`
    },
    ariaLabel(socialMediaName) {
      return this.ownerName !== '' 
        ? this.serviceOfOwner(socialMediaName, this.ownerName)
        : socialMediaName
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
.icons
  padding-bottom: 10px

a
  color: inherit
  text-decoration: none

i
  font-size: 2rem
</style>