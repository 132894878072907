<template>
  <div class="chat-rooms-list">
    <div v-for="(room, index) in rooms" :key="index">
      <h3>{{ room.name }}</h3>
      <button name="Hol" :value="room.hash" v-on:click="joinRoom(room.hash)">Join</button>
    </div>
  </div>
</template>

<script>
export default {
  props: { rooms: Array, joinRoom: Function },
  computed: {},
  methods: {},
  components: {}
};
</script>

<style lang="sass" tpe="text/sass" scoped>
.chat-rooms-list
  text-align: center
  border-style: solid
  position: relative
</style>
