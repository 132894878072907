<script setup>
import { computed } from "vue"
import ValidationError from '@/components/Survey/ValidationError.vue'
import RatingFive from "./QuestionType/RatingFive.vue"
import RatingNumber from "./QuestionType/RatingNumber.vue"
import RatingStar from "./QuestionType/RatingStar.vue"
import Select from "./QuestionType/Select.vue"
import SelectMultiple from "./QuestionType/SelectMultiple.vue"
import Textarea from "./QuestionType/Textarea.vue"
import { camelCase, startCase } from "lodash"

const props = defineProps({
  question: {
    default: () => {},
    type: Object
  },
  validation: Boolean,
  number: {
    default: "",
    type: String
  }
})

const questionTypes = {
  RatingFive,
  RatingNumber,
  RatingStar,
  Select,
  SelectMultiple,
  Textarea
}

const questionComponent = computed(() => {
  // Transform to PascalCase
  return questionTypes[startCase(camelCase(props.question.type)).replace(/ /g, '')]
})
</script>

<template>
  <div
    v-if="props.question"
    class="question-wrapper row"
    :class="`${props.question.type}`"
  >
    <div class="col-12 text-center col-md-2 text-md-end">
      <p class="index">
        {{ number }}
      </p>
    </div>
    <div class="col-12 col-md-8">
      <fieldset v-if="props.question.type === 'select_multiple' || props.question.type === 'rating_five'">
        <legend
          id="survey-question-legend"
          class="question text-center text-md-start question-title h3"
        >
          {{ props.question.description }} <span v-if="props.question.required">*</span>
        </legend>
        <ValidationError :validated="validation" />
        <form id="survey-question-form">
          <component
            :is="questionComponent"
            :question="props.question"
            :validation="validation"
          />
        </form>
      </fieldset>
      <div v-else>
        <label
          class="question text-center text-md-start question-title"
          for="survey-question"
        >
          {{ props.question.description }} <span v-if="props.question.required">*</span>
        </label>
        
        <ValidationError :validated="validation" />
        <form id="survey-question-form">
          <component
            v-if="props.question"
            :id="props.number"
            :is="questionComponent"
            :question="props.question"
            :validation="validation"
          />
        </form>
      </div>
    </div>
    <div class="col-md-2" />
  </div>
</template>

<style lang="scss" type="text/scss" scoped>
.question-number {
  font-weight: 600;
}

.question-title {
  font-weight: 600;
  margin-bottom: 35px;
}
</style>