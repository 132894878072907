<script>
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'

  export default {
    mounted () {
      this.appStore.set404({})
    },
    computed: {
      ...mapStores(useApp)
    }
  }
</script>