<template>
  <div>
    <div class="row justify-content-center">
      <div
        v-if="venue.image"
        class="col-lg-8 push-lg-2 text-center"
      >
        <img
          :src="venue.image"
          class="img-fluid"
          alt=""
        >
      </div>
    </div>
    <div class="row specs justify-content-center">
      <div class="col-12 text-start text-sm-center">
        <h2>{{ venue.title }}</h2>
      </div>
      <div class="col-lg-8">
        <div class="venue-details h3">
          <span v-if="venue.address">{{ venue.address }} <span v-if="venue.zip || venue.country"> | </span></span>
          <span v-if="venue.zip">{{ venue.zip }} {{ venue.city }} <span v-if="venue.country || (!venue.country && !venue.telephone && (venue.website || venue.email))"> | </span></span>
          <span v-if="venue.country">{{ venue.country }}<span v-if="venue.telephone || venue.website || venue.email"> | </span></span>
          <span v-if="venue.telephone">{{ venue.telephone }}<span v-if="venue.website || venue.email"> | </span></span>
          <a
            v-if="venue.website"
            :href="venue.website"
            target="_blank"
          >{{ getRootDomainFromUrl(venue.website) }} <span v-if="venue.email"> | </span></a>
          <a
            v-if="venue.email"
            :href="venue.email"
            target="_blank"
          > | {{ venue.email }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import urlfuncs from '../../mixins/urlfuncs'

  export default {
    mixins: [urlfuncs],
    props: {
      venue: Object
    }
  }
</script>

<style type="text/sass" lang="sass">
.venue-details
  text-align: center
@include media-breakpoint-down(sm)
  .venue-details
    text-align: left
.specs
  padding: 30px 0px 10px 0px

  h1
    font-weight: 600

  i
    cursor: pointer
    font-size: 2rem
    padding: 5px

  a
    color: inherit
    text-decoration: underline

  a:hover
    color: inherit
</style>
