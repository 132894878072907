<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6 col-lg-4 video" v-for="(video, i) in videos" :key="i">
                <div class="video-image-container">
                  <router-link
                    :to="goTo(video)"
                    :aria-label="goToDescription('video', video.title)"
                    >
                    <img :src="videoCover(video)" class="video-image" :alt="video.title">
                    <div class="play-container" aria-hidden="true">
                      <i class="icon-play"></i>
                    </div>
                  </router-link>
                </div>
                <div class="text-center pt-3">
                  <router-link
                    :to="goTo(video)"
                    :aria-label="goToDescription('video', video.title)"
                    class="mm-color-text text-center h3 video-title"
                  >
                    {{ video.title }}
                  </router-link>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.video-image-container
  position: relative
  cursor: pointer

  .play-container
    position: absolute
    width: 100%
    vertical-align: middle
    top: 50%
    margin-top: -3rem
    text-align: center

    i
      color: #FFF
      font-size: 5rem

.video
  margin-bottom: 20px
  a
    color: inherit

  .video-image
    background-color: #CCCCCC
    width: 100%
    height: 100%
    position: relative
    cursor: pointer
    object-fit: cover
    aspect-ratio: 16/9

    &:after
      content: ""
      display: block
      padding-bottom: 56.25%

.video-title
  font-weight: 600
  cursor: pointer
  text-decoration: none
</style>
<script>
  import VideoDetail from './VideoDetail'
  import goToDescriptionMixin from "../../mixins/goToDescriptionMixin"

  export default {
    props: {
      videos: Array
    },
    mixins: [
      goToDescriptionMixin
    ],
    components: {
      VideoDetail
    },
    data () {
      return {}
    },
    methods: {
      videoCover (video) {
        if (video.image) {
          return video.image
        }
        if (video.type == 'youtube') {
          return `https://img.youtube.com/vi/${video.value}/0.jpg`
        }
        if (video.type == 'vimeo') {
          /**
           * with thanks to https://stackoverflow.com/users/1397641/sam-carlton
           *
           * one day, this service will disappear, and on that day we will have to rewrite this entire
           * component to work asynchronously
           */
          try {
            let numberPattern = /\d+/g;
            let numbers = video.value.match(numberPattern)
            return "https://vumbnail.com/" + numbers[0] + ".jpg";
          } catch (err) {
            console.log(err)
          }
        }
        return 'none';
      },
      goTo(video) {
        return { name: 'page', params: { pageId: video.page_id, param1: video.id }}
      }
    }
  }
</script>
