<template>
  <div class="top-loading-indicator" v-show="showLoadingIndicator" :style="progressStyle" role="status">
    <span class="hide-from-viewport">{{ translate('top_loading_indicator_text') }}</span>
  </div>
</template>

<style lang="sass" type="text/sass">
.top-loading-indicator
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 3px
  background: #03a9f4
  z-index: 1000000
  transition: all .35s
</style>

<script>
  import { mapStores } from 'pinia'
  import { useAppUi } from '@/stores/app-ui'

  export default {
    data () {
      return {
        progress: 10,
        showLoadingIndicator: false,
        opacity: 1
      }
    },
    methods: {
      turnOff () {
        this.progress = 100
      },
      turnOn () {
        this.showLoadingIndicator = true
      }
    },
    computed: {
      ...mapStores(useAppUi),
      isAjaxLoading() {
        return this.appUiStore.ajaxLoading
      },
      progressStyle () {
        return {
          width: `${this.progress}%`,
          opacity: this.opacity
        }
      }
    },
    watch: {
      progress (value) {
        if (value >= 100) {
          setTimeout(() => {
            this.opacity = 0
            setTimeout(() => {
              this.showLoadingIndicator = false
            }, 400)
          }, 400)
        }
      },
      isAjaxLoading (isLoading) {
        clearInterval(this.interval)
        clearInterval(this.turnOffTimeout)
        if (!this.showLoadingIndicator && isLoading) {
          this.progress = 10
        }
        if (!isLoading) {
          this.turnOffTimeout = setTimeout(() => {
            this.turnOff()
          }, 350)
        }
        if (isLoading) {
          this.opacity = 1
          this.turnOn()
          this.interval = setInterval(() => {
            this.progress += (this.progress / 100 * (Math.random() * 5))
          }, 150)
        }
      }
    }
  }
</script>