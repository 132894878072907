<template>
  <div>
    <menu1 class="d-lg-none" />
    <div
      id="menu-2"
      class="d-none d-lg-block"
    >
      <div
        id="topmenu"
        :class="browser.name"
      >
        <router-link
          v-if="showHomepage"
          v-slot="{ navigate }"
          :to="{ name: 'home' }"
          custom
        >
          <div
            role="link"
            tabindex="0"
            :aria-current="ariaCurrentHomePage($route.name)"
            :class="{ active: 'home' === $route.name }"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <div class="menu-item">
              <span class="menu-wrapper">
                <i
                  class="icon-home"
                  aria-hidden="true"
                />
                <div class="menu-title">Home</div>
              </span>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="showLounge"
          v-slot="{ navigate }"
          :to="{ name: 'lounge' }"
          custom
        >
          <div
            role="link"
            tabindex="0"
            :aria-current="ariaCurrentLounge($route.name)"
            :class="{ active: 'lounge' === $route.name }"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <div class="menu-item">
              <span class="menu-wrapper">
                <i
                  class="icon-home"
                  aria-hidden="true"
                />
                <div class="menu-title">Home</div>
              </span>
            </div>
          </div>
        </router-link>
        <template v-if="menuActive">
          <template v-for="(p, index) in slicedPages">
            <div
              v-if="p.type == 'external'"
              :key="p.page_id"
              :class="{ active: p.page_id === parseInt($route.params.pageId) }"
              :aria-current="ariaCurrentPage(p.page_id,$route.params.pageId)"
              role="link"
              tabindex="0"
              @click="gotoExt(p.extra)"
              @keypress.enter="gotoExt(p.extra)"
            >
              <div class="menu-item">
                <span class="menu-wrapper">
                  <i
                    :class="p.icon"
                    aria-hidden="true"
                  />
                  <div class="menu-title">{{ p.title }}</div>
                </span>
              </div>
            </div>
            <router-link
              v-else
              v-slot="{ navigate }"
              :key="index"
              :to="{ name: 'page', params: { pageId: p.page_id } }"
              custom
            >
              <div
                role="link"
                tabindex="0"
                :class="{ active: p.page_id === parseInt($route.params.pageId) }"
                :aria-current="ariaCurrentPage(p.page_id,$route.params.pageId)" 
                :title="p.title" 
                @click="navigate"
                @keypress.enter="navigate"
              >  
                <div class="menu-item">
                  <span class="menu-wrapper">
                    <i
                      :class="p.icon"
                      aria-hidden="true"
                    />
                    <div class="menu-title">{{ p.title }}</div>
                  </span>
                </div>
              </div>
            </router-link>
          </template>
        </template>

        <div
          v-if="morePages.length > 0"
          class="more"
          role="link"
          tabindex="0"
          @keypress.enter="mouseEnterMore"
          @mouseenter="mouseEnterMore"
          @mouseleave="mouseLeaveMore"
        >
          <div class="menu-item">
            <span class="menu-wrapper">
              <i
                class="icon-menu"
                aria-hidden="true"
              />
              <div>{{ translate('menu_more') }}</div>
            </span>
          </div>
          <more
            v-show="isMenuOpen"
            :pages="morePages"
            :type="2"
          />
        </div>

        <LanguageDropdown />
      </div>
    </div>
  </div>
</template>

<script>
  import slice from 'lodash/slice'
  import Menu1 from './Menu1.vue'
  import More from './Partials/More.vue'
  import LanguageDropdown from "./Menu2/LanguageDropdown"
  import ariaMixin from "../../mixins/ariaMixin"
  import menuMixin from "../../mixins/menuMixin"
  import { mapStores } from 'pinia'
  import { useDevice } from '@/stores/device'
  import { useAppLounge } from '@/stores/app-lounge'
  import { useAppTemplate } from '@/stores/app-template'
  import { useAppPages } from '@/stores/app-pages'
  import { useAppMenu } from '@/stores/app-menu'

  export default {
    components: {
      Menu1,
      More,
      LanguageDropdown
    },
    mixins: [
      ariaMixin,
      menuMixin
    ],
    data () {
      return {}
    },
    computed: {
      ...mapStores(useDevice, useAppLounge, useAppTemplate, useAppPages, useAppMenu),
      browser() {
        return this.deviceStore.browser
      },
      showHomepage() {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      showLounge() {
        return this.appLoungeStore.active
      },
      template() {
        return this.appTemplateStore.template
      },
      pages() {
        return this.appPagesStore.menu
      },
      slicedPages() {
        if (this.pages.length === 3) {
          return slice(this.pages, 0, 3)
        }
        return slice(this.pages, 0, 2)
      },
      morePages() {
        if (this.pages.length <= 3) {
          return []
        }
        return slice(this.pages, 2)
      },
      menuActive() {
        return this.slicedPages.length > 1 || this.showHomepage || this.showLounge
      },
      isMenuOpen() {
        return this.appMenuStore.open
      }
    },
    methods: {
      gotoExt(extra) {
        window.open(JSON.parse(extra).link);
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
div#topmenu
  list-style-type: none
  display: flex
  justify-content: flex-end
  margin: 0
  padding: 0
  font-size: 0

  &.IE
    #language-dropdown
      top: -40px

  > div
    position: relative
    height: 100%
    cursor: pointer
    text-align: center
    padding: 0 10px
    font-size: 1rem
    display: flex
    align-items: center

    .menu-item
      display: flex
      height: 100%
      flex-direction: row

      span
        div
          font-weight: 600
          font-size: 0.89rem
          white-space: nowrap

    i
      font-size: 1.78rem

    span
      width: 100%
      white-space: nowrap

  div:not(.more)
    div.menu-title
      max-width: 180px
      overflow: hidden
      text-overflow: ellipsis

select#language-switch
  background-color: transparent

@include media-breakpoint-down(lg)
  div#topmenu
    height: $topbar-height-small

@include media-breakpoint-up(lg)
  div#topmenu
    height: $topbar-height-large

i
  font-style: normal
  line-height: 1
</style>
