<template>
  <div class="three-per-row">
    <router-link
      v-if="showHomepage"
      v-slot="{ navigate }"
      :to="{ name: 'home'}"
      custom
    >
      <div
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <router-link
      v-if="showLounge"
      v-slot="{ navigate }"
      :to="{ name: 'lounge'}"
      custom
    >
      <div
        class="item"
        @click="navigate"
        @keypress.enter="navigate"
      >
        <div class="borders" />
        <i
          class="icon-home"
          aria-hidden="true"
        />
        <span>Home</span>
      </div>
    </router-link>
    <template v-for="(item, index) in pages">
      <div
        v-if="item.type == 'external'"
        :key="item.page_id"
        class="item"
        @click="gotoExt(item.extra)"
      >
        <div class="borders" />
        <i
          v-if="item.icon"
          :class="item.icon"
          aria-hidden="true"
        />
        <i
          v-else
          class="icon-home placeholder"
          aria-hidden="true"
        />
        <span>{{ item.title }}</span>
      </div>
      <router-link
        v-else
        :key="index"
        v-slot="{ navigate }"
        :to="{ name: 'page', params: { pageId: item.page_id }}"
        custom
      >
        <div
          class="item"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <div class="borders" />
          <i
            v-if="item.icon"
            :class="item.icon"
            aria-hidden="true"
          />
          <i
            v-else
            class="icon-home placeholder"
            aria-hidden="true"
          />
          <span>{{ item.title }}</span>
        </div>
      </router-link>
    </template>
    <div
      v-for="(x, index) in rest"
      :key="index"
      class="item"
    >
      <div class="borders" />
      <i
        class="icon-home placeholder"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>
  import { mapStores } from 'pinia'
  import { useAppLounge } from '@/stores/app-lounge'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    name: 'Style3',
    props: {
      pages: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapStores(useAppLounge, useAppTemplate),
      rest () {
        let length = this.pages.length
        if (this.showHomepage) {
          length = length + 1
        }
        let rest = 3 - (length % 3)
        return (rest === 3) ? 0 : rest
      },
      showLounge () {
        return this.appLoungeStore.active
      },
      showHomepage () {
        let { homepage: showHomepage = true } = this.template
        return showHomepage
      },
      template () {
        return this.appTemplateStore.template
      }
    },
    methods: {
        gotoExt(extra) {
          window.open(JSON.parse(extra).link);
        }
    }
  }
</script>

<style type="text/sass" lang="sass" scoped>
$menuBorderSize: 100%
$menuBorderOffset: 0%

.three-per-row
    width: 100%

.item
    padding: 0
    display: inline-flex
    align-items: start
    justify-content: center
    width: 33.33%
    margin: 0
    box-sizing: border-box
    border: 0
    position: relative
    cursor: pointer
    width: 33.3333334vw
    height: 33.3333334vw
    [class^="icon-"], [class*=" icon-"]
        font-size: 35px
        font-style: normal
        margin-top: 15px

        &.placeholder
            visibility: hidden
    span
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        padding: 5%
        text-align: center
        font-size: 0.8rem
        line-height: .9rem
        padding-bottom: 15px

    .borders
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        border-right-width: 1px !important
        border-right-style: solid !important
        border-bottom-width: 1px !important
        border-bottom-style: solid !important
    &:nth-child(3n) .borders
      border-right: none
    &:nth-last-child(-n+3) .borders
      border-bottom: none

.item:after
    content: ''
    display: block
    padding-bottom: 100%
</style>
