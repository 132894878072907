<template>
  <footer data-cy="footer" id="mm-footer" class="mm-footer" v-if="showFooter">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-sm-4 text-center text-sm-start">
          <span class="m15tb text-left-footer">{{ text_left }}</span>
        </div>
        <div class="col-12 col-sm-8 text-center text-sm-end justify-content-center align-items-center">
          <a
            class="footer-link align-middle m15tb"
            target="_blank"
            v-for="(link, index) in footer.links"
            :key="index"
            :href="link.url"
          >
            {{ link.text }}
          </a>
          <router-link
            :to="{name: 'sitemap'}"
            class="footer-link align-middle m15tb"
            v-html="'Sitemap'"
            data-testid="sitemap"
          >
          </router-link>

          <SocialMediaIcons class="align-middle social-icons m15tb" :socialMediaLinks="footer.social"></SocialMediaIcons>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";
import SocialMediaIcons from "./SocialMedia/SocialMediaIcons"
import { mapStores } from "pinia"
import { useAppUi } from "@/stores/app-ui"
import { useAppTemplate } from "@/stores/app-template"

export default {
  data() {
    return {};
  },
  computed: {
    ...mapStores(useAppUi, useAppTemplate),
    footer() {
      return this.appTemplateStore.get("footer")
    },
    showFooter() {
      return this.appUiStore.footer && this.footer?.active
    },
    year: () => {
      return moment().year();
    },
    text_left() {
      let text_left = this.footer.text_left;
      if(text_left.indexOf("[year]") !== -1){
        return text_left.replace("[year]",this.year);
      }
      return this.footer.text_left;
    }
  },
  methods: {
    goToSitemap() {
      this.$router.push({name: 'sitemap'})
    }
  },
  components: {
    SocialMediaIcons
  }
};
</script>

<style lang="sass" type="text/sass">
#mm-footer
  padding: 35px 0

  .social-icons
    margin-left: 10px
    display: inline
    a::before
      font-size: 35px !important

  .container
    width: 100%
    max-width: 1280px

  .text-left-footer
    display: inline
    color: inherit
    font-weight: 600
    margin-bottom: 0.5rem

  a.footer-link
    text-decoration: underline
    font-weight: normal
    padding: 0 1rem
    cursor: pointer
    color: inherit

  @include media-breakpoint-only(md)
    a.footer-link
      display: inline-block
    .m15tb
      margin: 8px 0px

  @include media-breakpoint-only(xs)
    .social-icons
      display: block
      margin: 0
      margin-top: 10px
    a.footer-link
      display: inline-block
    .m15tb
      margin: 8px 0px

</style>
