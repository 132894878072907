<script setup>
import { useTranslations } from '@/composables/translations'

const { translate } = useTranslations()

const props = defineProps({
  previousRoute: {
    type: Object,
    default: null
  },
  nextRoute: {
    type: Object,
    default: null
  },
  overviewRoute: {
    type: Object,
    default: null
  }
})

</script>

<template>
  <div>
    <div
      v-if="props && props.overviewRoute"
      class="row navigation"
    >
      <div class="col-12 text-center">
        <span v-if="props.previousRoute">
          <router-link
            :to="props.previousRoute"
            :aria-label="translate('previous')"
          >
            <i
              class="icon-arrow-left"
              aria-hidden="true"
            />
          </router-link>
        </span>
        <span v-else>
          <i
            class="icon-arrow-left not_visible"
            aria-hidden="true"
            data-test="previous-not-visible"
          />
        </span>

        <router-link
          :to="props.overviewRoute"
          :aria-label="translate('overview')"
        >
          <i
            class="icon-overview index"
            aria-hidden="true"
          />
        </router-link>

        <span v-if="props.nextRoute">
          <router-link
            :to="props.nextRoute"
            :aria-label="translate('next')"
          >
            <i
              class="icon-arrow-right"
              aria-hidden="true"
            />
          </router-link>
        </span>
        <span v-else>
          <i
            class="icon-arrow-right not_visible"
            aria-hidden="true"
            data-test="next-not-visible"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
a
  text-decoration: none !important

.navigation
  padding: 20px 0px
  i
    cursor: pointer
    font-size: 2rem

  i.index
    padding: 0px 10px

  .not_visible
    visibility: hidden
</style>